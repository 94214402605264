import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button, Tooltip, styled } from "@mui/material";
import { alpha } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { DMA_CURRENCY, getUSDCPrice } from "../../utils/NFTs";
import { ReactComponent as USDTIcon } from "../../assets/icons/usdt.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/question_mark.svg";
import TooltipBg from "../../assets/tooltip-bg.png";
import { Box } from "@mui/system";
import { SALE_STAGE, useSaleCases } from "../../hooks/useSaleCases";
import LoadingView from "../ProductLoadingView";
import Copyright from "../../components/Copyright";
import { Helmet } from "react-helmet";
import {
  getDMA,
  getDMAInitial,
  getDMASaleStatus,
  getReferralCodeInfo,
  getDMAPrice
} from "../../redux/slices/DMA";
import DMACheckoutDialog from "../../components/DMACheckoutDialog";
import { PATH_EXTERNAL } from "../../routes/paths";
import { authUser } from "../../redux/slices/auth";
import useBreakpoints from "../../hooks/useBreakpoints";
import { motion } from "framer-motion";
import axios from "../../utils/axios";

const buyButtonSX = {
  display: "flex",
  flexFlow: "column nowrap",
  textTransform: "uppercase",
  width: "100%",
  fontSize: 16
};

const videoSX = {
  height: "auto",
  width: "100%",
  maxWidth: { md: "622px" },
  backgroundPosition: "center",
  backgroundSize: "104px auto"
};

const buttonContainerSX = {
  position: { xs: "sticky", md: "relative" },
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  width: "100%",
  flexFlow: "column nowrap",
  mt: { xs: 1, md: 2.5 },
  background: (theme) => {
    return {
      xs: `linear-gradient(${alpha(theme.palette.background.default, 0.5)}, ${
        theme.palette.background.default
      })`
    };
  },
  pb: { xs: 1, md: 0 }
};

const DMA_TITLE = "Delysium Multiverse Accelerator";

const CountdownView = ({ timeLeft, isWaiting, isPreSale, isOpenSeaSale, isSoldout }) => {
  const [days, hours, minutes, seconds] = timeLeft;

  if (isSoldout) {
    return (
      <Typography variant="caption" color="textSecondary" mt={1}>
        Sale ended. Please stay tuned to{" "}
        <Typography
          component="a"
          href="https://discord.com/invite/delysium"
          target="_blank"
          variant="primary"
          sx={{ textDecoration: "none" }}>
          Delysium
        </Typography>
      </Typography>
    );
  }
  if (isOpenSeaSale) {
    return null;
  }

  function Placeholder({ children }) {
    return (
      <Typography variant="caption" color="primary">
        {" "}
        {children}{" "}
      </Typography>
    );
  }

  return (
    <Box display="flex" mt={1}>
      <Typography variant="caption" color="textSecondary">
        {/* Render units in singular or plural form */}
        {isPreSale ? "Pre-Sale" : "Offer"}
        &nbsp;{isWaiting ? "Starts" : "Ends"} in
        <Placeholder>{days}</Placeholder>
        Days,
        <Placeholder>{hours}</Placeholder>
        Hours,
        <Placeholder>{minutes}</Placeholder>
        Mins,
        <Placeholder>{seconds}</Placeholder>
        Secs
      </Typography>
    </Box>
  );
};

function DMATitle({ ...props }) {
  return (
    <Typography
      component="h1"
      {...props}
      sx={{
        mb: { xs: 2, md: 0 },
        fontSize: { xs: "24px", md: "36px", fontWeight: "bold", textTransform: "uppercase" },
        ...props?.sx
      }}>
      {DMA_TITLE}
    </Typography>
  );
}

const ToBeStyledTooltip = ({ className, ...props }) => {
  const downMd = useBreakpoints("down", "md");
  return (
    <Tooltip
      {...props}
      enterTouchDelay={0}
      disableFocusListener
      leaveDelay={downMd ? 1000 : 300}
      classes={{ tooltip: className }}
    />
  );
};

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  width: "203px",
  height: "94px",
  color: theme.palette.grey[900],
  backgroundColor: "transparent",
  marginLeft: "10px"
}));

const DMAView = () => {
  const dispatch = useDispatch();
  const { modelId } = useParams();
  const videoRef = useRef(null);
  const [adminDisabledSale, setAdminDisabledSale] = useState(false);
  const { isAuthenticated, token } = useSelector((state) => state.auth);
  const { DMA, isLoading, submittedReferralCode, isRefreshingPrice, dmaPrice } = useSelector(
    (state) => state.DMA
  );
  const [isBuyOpen, setIsBuyOpen] = useState(false);

  const { timeLeft, currentSaleStage } = useSaleCases({
    preSaleStartDate: 0,
    preSaleEndDate: 0,
    publicSaleStartDate: DMA?.sale_start_time * 1000,
    publicSaleEndDate: DMA?.on_chain_info?.TimeSaleEnd * 1000
  });

  // Computed
  const stockQuantity = DMA?.on_chain_info?.MaxSupply - DMA?.on_chain_info?.NumSold;
  const buyButtonText = "Buy Now";

  // Stage alias
  const isWaitingPreSale = currentSaleStage === SALE_STAGE.WAITING_PRE_SALE;
  const isPreSale = currentSaleStage === SALE_STAGE.PRE_SALE;
  const isWaitingPublicSale = currentSaleStage === SALE_STAGE.WAITING_PUBLIC_SALE;
  const isPublicSale = currentSaleStage === SALE_STAGE.PUBLIC_SALE;
  const isOpenSeaSale = currentSaleStage === SALE_STAGE.OPENSEA_SALE; // Currently we will not have opensea sale

  const isDisabledBuyButtonConds = [
    () => adminDisabledSale,
    () => isPreSale,
    () => isOpenSeaSale,
    () => isWaitingPreSale || isWaitingPublicSale,
    () => !stockQuantity,
    () => isLoading
  ];

  useEffect(() => {
    const { current: videoElement } = videoRef;
    videoElement.setAttribute("muted", "");
  }, []);

  useEffect(() => {
    getDMASaleStatus().then((res) => setAdminDisabledSale(res));
  }, []);

  function handleClickOnBuy() {
    if (!isAuthenticated) {
      window.location.assign(PATH_EXTERNAL.sso.login);
      return;
    }
    if (stockQuantity === 0) return;

    setIsBuyOpen(true);
  }

  useEffect(() => {
    if (!submittedReferralCode) {
      dispatch(getReferralCodeInfo());
    }
  }, []);

  useEffect(() => {
    const getDMAInfo = setInterval(function () {
      // dispatch(getDMA());
    }, 12000);

    return () => clearInterval(getDMAInfo);
  }, []);

  useEffect(() => {
    dispatch(getDMAInitial());
  }, [dispatch, modelId]);

  useEffect(() => {
    if (token) dispatch(authUser(token));
  }, [dispatch, token, isAuthenticated]);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Grid
      container
      sx={{
        px: { xs: 0.5, md: 0 },
        my: { xs: 2, md: 8 },
        position: "relative",
        flexFlow: { md: "row nowrap" },
        justifyContent: { md: "center" }
      }}>
      <Helmet>
        <title>{`${DMA_TITLE} - Delysium Marketplace`}</title>
      </Helmet>

      <DMATitle sx={{ display: { md: "none" } }} />

      <Grid item md={6} sx={{ position: "relative", margin: { xs: "0 auto", md: "initial" } }}>
        <Box
          component="video"
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          ref={videoRef}
          src="https://storage.delysium.com/ui/dm_series/dma_m.mp4"
          alt={DMA_TITLE}
          sx={videoSX}
        />
      </Grid>

      <Grid
        md={6}
        item
        maxWidth={"500px"}
        sx={{
          maxWidth: { md: "500px" },
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "flex-start",
          justifyContent: "space-evenly",
          mx: { md: 8 }
        }}>
        <DMATitle sx={{ display: { xs: "none", md: "initial" } }} />

        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: { md: 0.625 } }} component="h3" fontSize={16} color="primary">
            Price
          </Typography>

          <Box sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
            <USDTIcon width={20} height={20} style={{ marginRight: "0.5rem" }} />
            <Typography component="p" color="textPrimary" variant="h4">
              <Typography
                component={motion.span}
                animate={{ opacity: isRefreshingPrice ? 0 : 1 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                variant="h4"
                color="inherit">
                {dmaPrice.usdt_price}{" "}
              </Typography>
              {DMA_CURRENCY}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            my: { xs: 2, md: 0 }
          }}>
          <Box>
            <Typography sx={{ mb: { md: 0.625 } }} component="h3" fontSize={16} color="primary">
              Total Supply
            </Typography>

            <Typography color="textPrimary" variant="caption" component="p">
              {Number(DMA?.on_chain_info?.MaxSupply).toLocaleString()}
            </Typography>
          </Box>
        </Box>

        {/* ------------- INTRO -------------   */}

        <Box sx={{ mt: { xs: 2, md: 5 } }}>
          <Typography sx={{ mb: 0.625, fontSize: 16 }} component="h3" color="primary">
            Intro
          </Typography>

          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ mt: 1.25, opacity: 0.7, fontSize: { xs: 12 }, fontWeight: 300 }}>
            Delysium Multiverse Accelerator(DMA) is an enhanced access to select, vote and support
            each Delysium Universe to become more prosperous, thus sharing benefits from the
            Ecosystem.
          </Typography>
        </Box>
        <Copyright sx={{ display: { md: "none" }, mx: 0, mt: 3, p: 0 }} />

        {/* ------------- TRADE/BUY/SALE -------------   */}
        <Box sx={buttonContainerSX}>
          <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
            <Button
              disableRipple
              data-augmented-ui="tl-clip br-clip"
              variant="contained"
              color="primary"
              size="large"
              disabled={isDisabledBuyButtonConds.some((fn) => fn())}
              onClick={handleClickOnBuy}
              sx={buyButtonSX}>
              {stockQuantity <= 0 ? "Sold Out" : isOpenSeaSale ? "Sale Ended" : buyButtonText}
              <Typography
                variant="body2"
                sx={{ textTransform: "capitalize", fontSize: 12, fontWeight: 300 }}>
                {`Available Supply ${stockQuantity}`}
              </Typography>
            </Button>

            <StyledTooltip
              //Use to control the distance between the anchor and the tooltip
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [-70, -10]
                    }
                  }
                ]
              }}
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    p: 2,
                    backgroundImage: `url(${TooltipBg})`,
                    backgroundRepeat: "no-repeat"
                  }
                }
              }}
              title={
                <Typography color="textPrimary" fontSize={12} fontWeight={400}>
                  For more details regarding DMA purchases, please refer to{" "}
                  <Box
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://delysium.gitbook.io/delysium-multiverse-product/delysium-multiverse-accelerator/delysium-dma-purchase-guide">
                    DMA Purchase Guide
                  </Box>
                </Typography>
              }>
              <Box ml={1.125} display="flex" alignItems="center">
                <QuestionMarkIcon />
              </Box>
            </StyledTooltip>
          </Box>

          {isPublicSale ? null : (
            <CountdownView
              isSoldout={stockQuantity <= 0}
              isWaiting={isWaitingPreSale || isWaitingPublicSale}
              timeLeft={timeLeft}
              isPreSale={isWaitingPreSale || isPreSale}
              isOpenSeaSale={isOpenSeaSale}
            />
          )}
          <Copyright sx={{ display: { xs: "none", md: "flex" }, mx: 0, mt: 3, p: 0 }} />
        </Box>
      </Grid>
      {isBuyOpen && (
        <DMACheckoutDialog
          isOpen={isBuyOpen}
          setClose={() => setIsBuyOpen(false)}
          isPreSale={isPreSale}
          isPublicSale={isPublicSale}
        />
      )}
    </Grid>
  );
};

export default DMAView;
