import { InjectedConnector as MetaMask } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector as CoinBaseConnector } from "@web3-react/walletlink-connector";
import { CHAINS } from "./chains";
import { __DEBUG__, __STAGING__ } from "./dev";
import { COINBASE, METAMASK, WALLETCONNECT } from "./wallet";

export const injected = new MetaMask();

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: CHAINS[1].rpc,
    5: CHAINS[5].rpc,
    11155111: CHAINS[11155111].rpc,
    137: CHAINS[137].rpc,
    80001: CHAINS[80001].rpc
  },
  chainId: __STAGING__ || __DEBUG__ ? 5 : 1,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});

export const walletlink = new CoinBaseConnector({
  rpc: CHAINS,
  appName: "Delysium"
});

export function getConnectorName(connector) {
  if (connector instanceof MetaMask) return METAMASK;
  if (connector instanceof WalletConnectConnector) return WALLETCONNECT;
  if (connector instanceof CoinBaseConnector) return COINBASE;
  return "Unknown";
}
