import { PATH_MARKETPLACE } from "./paths";
import React from "react";
import MainLayout from "../layouts/MainLayout";
import NFTList from "../views/NFTList";

// ----------------------------------------------------------------------

const MarketplaceRoutes = {
  exact: true,
  path: PATH_MARKETPLACE.root,
  layout: MainLayout,
  component: () => <NFTList isInventory={false} />
};

export default MarketplaceRoutes;
