import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PropTypes } from "prop-types";
import CustomDivider from "../../components/Divider";
import { NFT_VIEW_SPACING as SPACING } from "../../constants/styles";
// ----------------------------------------------------------------------
SaleStageView.propTypes = {
  isWaitingPreSale: PropTypes.bool,
  isPreSale: PropTypes.bool,
  isWaitingPublicSale: PropTypes.bool,
  isPublicSale: PropTypes.bool,
  isOpenSeaSale: PropTypes.bool,

  isPreSaleOnlyMode: PropTypes.bool,
  isPublicSaleOnlyMode: PropTypes.bool,

  preSaleDateRange: PropTypes.string,
  publicSaleDateRange: PropTypes.string
};

// ----------------------------------------------------------------------
export function SaleStageView({
  // Stages
  isWaitingPreSale,
  isPreSale,
  isWaitingPublicSale,
  isPublicSale,
  isOpenSeaSale,

  // Modes
  isPreSaleOnlyMode,
  isPublicSaleOnlyMode,

  // Dates
  preSaleDateRange = "10th – 15th September, 2022.",
  publicSaleDateRange = "16th – 30th September, 2022.",
  ...props
}) {
  if (isOpenSeaSale) {
    return null;
  }

  const preSaleStageTitle = isWaitingPreSale
    ? "Pre-Sale"
    : isPreSale
    ? `Pre-Sale (Current)`
    : "Pre-Sale (END)";

  return (
    <>
      <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />

      <Box {...props}>
        <Grid sx={{ mt: 1 }} container spacing={[0, 3]}>
          {!isPublicSaleOnlyMode && (
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  mb: 0.625,
                  textDecoration: isWaitingPreSale || isPreSale ? "none" : "line-through"
                }}
                component="h3"
                fontSize={16}
                fontWeight={isPreSale ? 600 : 500}
                color={isPreSale ? "primary" : "textSecondary"}>
                {preSaleStageTitle}
              </Typography>
              <Typography
                component="div"
                variant={isPreSale ? "caption" : "caption2"}
                color={isPreSale ? "#F58A4E" : "textSecondary"}
                sx={{ opacity: isPreSale ? 1 : 0.7 }}>
                Pre-Sale Only Supports Whitelist
                <br />
              </Typography>
              <Typography
                component="div"
                variant={isPreSale ? "caption" : "caption2"}
                color="textSecondary"
                sx={{ opacity: isPreSale ? 1 : 0.7 }}>
                Available Time: {preSaleDateRange}
              </Typography>
            </Grid>
          )}
          {!isPreSaleOnlyMode && (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ order: isWaitingPublicSale || isPublicSale ? -1 : 0 }}>
              <Typography
                sx={{ mb: 0.625 }}
                component="h3"
                fontSize={16}
                fontWeight={isPublicSale ? 600 : 500}
                color={isPublicSale ? "primary" : "textSecondary"}>
                Fair Launch{isPublicSale && " (Current) "}
              </Typography>
              <Typography
                component="div"
                variant={isPublicSale ? "caption" : "caption2"}
                color="textSecondary"
                sx={{ opacity: isPublicSale ? 1 : 0.7 }}>
                Available Time: {publicSaleDateRange}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
