import mediumIcon from "../assets/icons/medium.png";
import twitterIcon from "../assets/icons/twitter.png";
import discordIcon from "../assets/icons/discord.png";
import telegramIcon from "../assets/icons/telegram.png";
import youtubeIcon from "../assets/icons/youtube.png";
import { PATH_DMA, PATH_EXTERNAL, PATH_INVENTORY, PATH_MARKETPLACE } from "../routes/paths";

export const PAGES = [
  // { title: "NFT", url: PATH_MARKETPLACE.root },
  { title: "DMA", url: PATH_DMA.root },
  // { title: "inventory", url: PATH_INVENTORY.root },
  {
    title: "community",
    sublists: {
      About: [
        { title: "Introduction", url: PATH_EXTERNAL.website.about },
        { title: "Whitepaper", url: PATH_EXTERNAL.website.whitepaper }
      ],
      "Social Media": [
        { title: "Medium", icon: mediumIcon, url: "https://delysium.medium.com" },
        {
          title: "Twitter",
          icon: twitterIcon,
          url: "https://twitter.com/The_Delysium"
        },
        {
          title: "Discord",
          icon: discordIcon,
          url: "https://discord.gg/UQbV5B2Wkj"
        },
        {
          title: "Telegram",
          icon: telegramIcon,
          url: "https://t.me/TheDelysium"
        },
        {
          title: "YouTube",
          icon: youtubeIcon,
          url: "https://www.youtube.com/channel/UCalVfmQbvDKVZlz8Cypo9xA/about"
        }
      ]
    }
  }
];
