import { PATH_INVENTORY } from "./paths";
import React from "react";
import MainLayout from "../layouts/MainLayout";
import NFTList from "../views/NFTList";

// ----------------------------------------------------------------------

const InventoryRoutes = {
  exact: true,
  path: PATH_INVENTORY.root,
  layout: MainLayout,
  component: () => <NFTList isInventory />
};

export default InventoryRoutes;
