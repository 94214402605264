export default function Select({ theme }) {
  return {
    MuiSelect: {
      defaultProps: {
        // IconComponent: ExpandMoreRoundedIcon
      },

      styleOverrides: {
        root: {
          position: "relative",
          overflow: "hidden",
          color: "inherit",

          "&:hover": {
            "&::after": {
              borderColor: "text.primary"
            },
            "&::before": {
              borderColor: "text.primary"
            }
          }
        }
      }
    }
  };
}
