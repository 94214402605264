// ----------------------------------------------------------------------

export default function Dialog({ theme }) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          p: "0.15rem 0.25rem",
          overflow: "initial",
          minWidth: { xs: "initial", sm: "373px" },
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[25].z24,
          "&.MuiPaper-rounded": {
            borderRadius: theme.shape.borderRadiusMd
          },
          "&.MuiDialog-paperFullScreen": {
            borderRadius: 0
          },
          "&.MuiDialog-paper .MuiDialogActions-root": {
            padding: 24
          },
          "@media (max-width: 600px)": {
            margin: 16
          },
          "@media (max-width: 663.95px)": {
            "&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody": {
              maxWidth: "100%"
            }
          }
        },
        paperFullWidth: {
          width: "100%"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 24,
          paddingBottom: 0
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
          borderTop: 0,
          borderBottom: 0
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "& > :not(:first-of-type)": {
            marginLeft: 12
          }
        }
      }
    }
  };
}
