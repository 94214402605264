import { __PROD__, __STAGING__ } from "./utils/dev";

export const config = {
  DMA_ARK_CONFIG_URL: __PROD__
    ? "https://ark.entermetaverse.fun/static/config/16ba9b9d-ed81-4100-8564-988eff81bac2.json"
    : "https://ark.entermetaverse.fun/static/config/8b47b963-aeaf-4c2c-8672-3d5cd320a5c5.json",
  SUIT_ARK_CONFIG_URL:
    "https://ark.entermetaverse.fun/static/config/254dbbd7-8941-4821-89c5-0de46989a43b.json",
  GLOBAL_ARK_CONFIG_URL: __PROD__
    ? "https://ark.entermetaverse.fun/static/config/d876bf0c-4b81-4109-927a-99a6d5f38cf3.json"
    : "https://ark.entermetaverse.fun/static/config/4e6bb35f-e14a-451f-bf25-15390b4ef78b.json",
  SSO_CLIENT_URL: __PROD__
    ? "https://auth.delysium.com"
    : __STAGING__
    ? "https://auth-staging.delysium.com"
    : "https://auth-dev.delysium.com",
  WEB_CLIENT_URL: __PROD__ ? "https://delysium.com" : "https://www-staging.delysium.com",
  DASHBOARD_CLIENT_URL: __PROD__
    ? "https://dashboard.delysium.com"
    : __STAGING__
    ? "https://dashboard-staging.delysium.com"
    : "https://dashboard-dev.delysium.com",
  SSO_API_URL: __PROD__
    ? "https://sso.delysium.com"
    : __STAGING__
    ? "https://sso-stg.delysium.com"
    : "https://sso-dev.delysium.com",
  MARKET_API_URL: __PROD__
    ? "https://market-api.delysium.com/api/v1"
    : __STAGING__
    ? "https://market-api-staging.delysium.com/api/v1"
    : "https://market-api-dev.delysium.com/api/v1",
  DMA_API_URL: __PROD__
    ? "https://dma-api.delysium.com/api/v1"
    : __STAGING__
    ? "https://dma-api-staging.delysium.com/api/v1"
    : "https://dma-api-dev.delysium.com/api/v1"
};
