import React from "react";
import { Tab, alpha, Tabs, Button } from "@mui/material";
import SelectedTabBorder from "../assets/navigation-selected.png";
import HoverTabBorder from "../assets/navigation-hover.png";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeIn } from "./Animate";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { isMobile } from "../utils/env";

// ----------------------------------------------------------------------
TabGroup.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
// ----------------------------------------------------------------------
const sharedSX = {
  overflow: "initial",
  height: "initial",
  width: "80%",
  margin: "0 auto",
  opacity: "initial"
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

function SVGBorderContainer() {
  return (
    <AnimatePresence>
      <motion.img
        src={SelectedTabBorder}
        style={{
          position: "absolute",
          zIndex: -1,
          top: "50%",
          left: 0,
          transform: "translate(0, -50%)",
          maxWidth: "initial",
          maxHeight: "48px",
          height: "auto",
          width: "100%"
        }}
        {...varFadeIn}
      />
    </AnimatePresence>
  );
}
function TabGroup({
  value,
  label,
  items,
  selectedItem,
  handleClick = () => {},
  onChange,
  ...props
}) {
  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={selectedItem}
      TabIndicatorProps={{ sx: { display: "none" } }}
      textColor="inherit"
      onChange={onChange}
      aria-label={label}
      {...props}
      sx={{ py: 1, ...props?.sx }}>
      {items.map(({ title, value }, index) => {
        const isSelected = value === selectedItem;
        //@TODO: Change the static if/else of isComingSoon
        const isComingSoon =
          value !== "" && value !== "Collectible" && value !== "Weapon" && value !== "Outfit";

        return isComingSoon ? (
          <Box
            key={index}
            component={Button}
            onClick={(e) => {
              if (isMobile()) {
                e.target.innerText = "Coming Soon...";
              }
            }}
            onMouseLeave={(e) => {
              e.target.innerText = title;
            }}
            onMouseOver={(e) => {
              e.target.innerText = "Coming Soon...";
            }}
            sx={{
              ...sharedSX,
              fontWeight: isSelected ? 600 : 300,

              color: (theme) =>
                isSelected ? theme.palette.text.primary : alpha(theme.palette.text.primary, 0.8),

              "&:hover": {
                backgroundColor: "initial"
              }
            }}>
            {title}
          </Box>
        ) : (
          <Tab
            onClick={() => handleClick(title)}
            key={index}
            value={value}
            disableRipple
            {...a11yProps(value)}
            sx={{
              ...sharedSX,
              fontWeight: isSelected ? 600 : 300,

              color: (theme) =>
                isSelected ? theme.palette.text.primary : alpha(theme.palette.text.primary, 0.8),

              "&:hover": {
                backgroundImage: !isSelected && `url(${HoverTabBorder})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain"
              }
            }}
            label={
              <>
                {title}
                {isSelected && <SVGBorderContainer />}
              </>
            }
          />
        );
      })}
    </Tabs>
  );
}

export default TabGroup;
