import React, { useState } from "react";
import { MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import { ReactComponent as DropdownArrow } from "../assets/arrow-dropdown.svg";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------

CustomSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
  color: PropTypes.string,
  LabelProps: PropTypes.object,
  MenuProps: PropTypes.object,
  MenuItemProps: PropTypes.object
};
// ----------------------------------------------------------------------

function CustomSelect({
  value,
  onChange,
  label,
  options = [],
  size = "small",
  color = "secondary",
  LabelProps,
  MenuProps,
  MenuItemProps,
  ...props
}) {
  const [hovered, setHovered] = useState(null);
  return (
    <FormControl sx={{ height: { xs: "32px", md: "initial" } }} size={size} fullWidth>
      <InputLabel
        sx={{
          width: "4rem",
          textAlign: "center",
          textTransform: "capitalize",
          background: (theme) => theme.palette.background.default
        }}>
        {label}
      </InputLabel>
      <Select
        IconComponent={() => (
          <DropdownArrow
            style={{ position: "absolute", width: 10, height: "0.45rem", right: 10 }}
          />
        )}
        size={size}
        data-augmented-ui="tl-clip br-clip border"
        value={value}
        color={color}
        label={label}
        MenuProps={MenuProps}
        onChange={onChange}
        inputProps={{
          sx: { display: "flex", alignItems: "center" }
        }}
        sx={{
          textTransform: "capitalize"
        }}
        {...props}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            onMouseEnter={() => setHovered(option.value)}
            sx={{
              position: "relative",

              //This is in case one of the rarity values are set as options
              "&::before": {
                content: "''",
                display: value === option.value ? "initial" : "none",
                position: "absolute",
                left: 8,
                top: "%50",
                width: "3px",
                height: "30%",
                background: (theme) => theme.palette.primary.main
              },
              "&:hover": {
                backgroundColor: "initial",
                "&.Mui-selected": {
                  backgroundColor: "initial"
                }
              },
              "&.Mui-selected": {
                backgroundColor: "initial !important"
              }
            }}
            {...MenuItemProps}>
            {option.icon ? (
              hovered === option.value ? (
                <option.hoverIcon />
              ) : (
                <option.icon />
              )
            ) : (
              option.title
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
