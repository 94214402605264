import { config } from "../config";
import axios from "axios";
import ReactGA from "react-ga4";

// inject redux state to avoid circular dependency issue
let store;
export const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL: config.MARKET_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (req) => {
    const globalState = store.getState();
    const { token } = globalState.auth;

    if (token) {
      if (req.url.includes(config.SSO_API_URL)) req.headers["Authorization"] = `Bearer ${token}`;
      else if (req.url.includes("sign") || req.url.includes("invitation-code") || req.baseURL.includes(config.DMA_API_URL))
        req.headers["dely-jwt"] = `Bearer ${token}`;
    }

    if (req.method !== "get") {
      const combinator = ~req.url.indexOf("?") ? "&" : "?";
      req.url = `${req.url}${combinator}`;
      if (req.method === "post") req.headers["Content-Type"] = "application/json";
    }
    return req;
  },
  (error) => console.log(error)
);

axiosInstance.interceptors.response.use(
  async (res) => {
    if (res.data?.error_detail?.includes("JWT")) {
      return Promise.reject("Your login credentials are not valid, please try logging in again");
    }
    return res;
  },

  async (err) => {
    ReactGA.send({ hitType: "exception", exDescription: err.response, exFatal: false });
    return Promise.reject(err.response && err.response.data);
  }
);

export default axiosInstance;
