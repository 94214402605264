import { PATH_NFT } from "./paths";
import NFTLayout from "../layouts/NFTLayout";
import NFTView from "../views/NFT";
import IMXNFTView from "../views/IMXNFT";

// ----------------------------------------------------------------------

const NFTRoutes = {
  path: PATH_NFT.root,
  layout: NFTLayout,
  routes: [
    { path: PATH_NFT.suiteNFT, exact: true, component: () => <IMXNFTView /> },
    { path: PATH_NFT.NFTView, exact: false, component: () => <NFTView /> }
  ]
};

export default NFTRoutes;
