import { PATH_OWNED_NFT } from "./paths";
import NFTLayout from "../layouts/NFTLayout";
import NFTView from "../views/NFT";

// ----------------------------------------------------------------------

const OwnedNFTRoutes = {
  path: PATH_OWNED_NFT.root,
  layout: NFTLayout,
  component: () => <NFTView isOwned />
};

export default OwnedNFTRoutes;
