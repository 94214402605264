import React from "react";
import { Box, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import CustomSkeleton from "./Skeleton";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------

InfoBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSkeleton: PropTypes.bool
};
// ----------------------------------------------------------------------

const borderOffSet = "-5px";

const styles = ({ theme }) => {
  const { secondary: textSecondary } = theme.palette.text;
  const borderColor = alpha(textSecondary, 0.3);

  return {
    position: "relative",
    zIndex: 4,
    height: "4rem",
    px: 1.5,
    py: 1,
    minWidth: "170px",
    borderTop: `1px solid ${borderColor}`,

    // @FIXME: Reduce the size of triangle
    "&::before": {
      content: "''",
      position: "absolute",
      width: "11px",
      height: "11px",
      transform: "rotate(-45deg)",
      top: borderOffSet,
      left: borderOffSet,
      background: theme.palette.background.default,
      borderBottom: `1px solid ${borderColor}`
    },

    "&::after": {
      content: "''",
      position: "absolute",
      left: "-1px",
      top: "-1px",
      width: "0.5rem",
      height: "0.5rem",
      backgroundColor: borderColor,
      clipPath: "polygon(100% 0, 0 0, 0 100%)"
    }
  };
};
function InfoBox({ title = "title", subtitle = "subtitle", isSkeleton = false, ...props }) {
  const theme = useTheme();
  return (
    <Box sx={{ ...styles({ theme }) }} {...props}>
      <Typography
        sx={{ textTransform: "capitalize", opacity: 0.9 }}
        variant="caption2"
        color="textSecondary"
        component={isSkeleton ? "div" : "p"}>
        {isSkeleton ? <CustomSkeleton width="80%" mt={2} /> : title.replace(/_/g, " ")}
      </Typography>
      <Typography
        sx={{ textTransform: "capitalize", opacity: 0.85, mt: "2px" }}
        variant="body1"
        color="textPrimary"
        component={isSkeleton ? "div" : "p"}>
        {isSkeleton ? <CustomSkeleton width="50%" mt={2} /> : subtitle}
      </Typography>
    </Box>
  );
}

export default InfoBox;
