import { useState } from "react";
import {
  Toolbar,
  Box,
  AppBar,
  Typography,
  Container,
  MenuItem,
  Button,
  IconButton,
  alpha,
  Grow
} from "@mui/material";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles, styled } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet/wallet.svg";
import { ReactComponent as WalletWhiteIcon } from "../../../assets/icons/wallet/wallet-white.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as UserWhiteIcon } from "../../../assets/icons/user-white.svg";
import WalletPopover from "../WalletPopover";
import { useWeb3React } from "@web3-react/core";
import { useEagerConnect } from "../../../hooks/useEagerConnect";
import { useInactiveListener } from "../../../hooks/useInactiveListener";
import { PAGES } from "../../../constants/pages";
import ChangeNetworkAlert from "../../../components/ChangeNetworkAlert";
import ReactGA from "react-ga4";
import { PATH_EXTERNAL } from "../../../routes/paths";
import AccountPopover from "../AccountPopover";
import { __STAGING__ } from "../../../utils/dev";
import { AnimatePresence } from "framer-motion";

const CDN_ASSETS = {
  LOGO: __STAGING__
    ? "https://storage.delysium.com/ui/delysium_logo_staging.png"
    : "https://storage.delysium.com/ui/delysium_logo.png"
};

const CommunityHoverDropdown = styled(({ className, ...props }) => (
  <Tooltip
    // open
    onOpen={props.onOpen}
    onClose={props.onClose}
    placement="bottom-start"
    TransitionComponent={Grow}
    leaveDelay={100}
    TransitionProps={{ timeout: 350 }}
    classes={{ popper: className }}
    {...props}
  />
))(({ theme }) => ({
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    zIndex: -1,
    background: "black",
    left: "-100vw",
    top: "4px",
    width: "200vw",
    height: "100%",
    borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
    animation: "growIn 0.2s ease"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    position: "relative",
    backgroundColor: "#000",
    color: theme.palette.text.primary,
    fontSize: 14,
    padding: "17px 14px 1px"
  }
}));

const appBarSX = {
  position: "relative",
  width: "initial",
  mt: "20px",
  backgroundImage: "initial",
  backgroundColor: "initial",
  color: (theme) => theme.palette.text.primary
};

const buttonSX = {
  display: "inline-flex",
  padding: "5px",
  width: 120,
  height: 32,
  mr: 1.25,
  "--aug-border-bg": (theme) => theme.palette.text.primary,

  "&:hover": {
    backgroundColor: "transparent",
    "--aug-border-bg": (theme) => theme.palette.primary.main,
    "& > p": {
      color: (theme) => theme.palette.primary.main
    }
  },

  "& > p": {
    fontSize: "12px"
  },

  "& > span": {
    marginRight: "0"
  }
};

const useStyles = makeStyles((theme) => {
  const underlineStyles = {
    content: "''",
    position: "absolute",
    bottom: "-3px",
    left: `${theme.spacing(2)}`,
    right: `${theme.spacing(2)}`,
    borderBottom: `2px solid ${theme.palette.text.primary}`,
    transition: "all 0.2s ease-in-out",
    opacity: 0
  };
  return {
    navLink: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      fontSize: "14px",
      margin: 0,
      textDecoration: "none",
      textTransform: "uppercase",
      transition: "all 0.4s ease-in-out",
      padding: "0 15px",

      "&::before": underlineStyles,

      "&:hover": {
        backgroundColor: "transparent",
        "&::before": {
          opacity: 1
        }
      },

      "& > button": {
        color: theme.palette.text.primary,
        textTransform: "uppercase",
        height: "100%",
        fontSize: 14,
        fontWeight: 400,
        minWidth: "initial",
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    activeNavLink: {
      backgroundColor: "transparent",
      "&::before": {
        ...underlineStyles,
        opacity: 1
      }
    },

    dropdownMenuItem: {
      zIndex: theme.zIndex.appBar + 1,
      fontWeight: 600,
      fontSize: "14px",
      height: "18px",
      padding: "5px",
      paddingLeft: 0,
      margin: "6px auto",
      textTransform: "capitalize",
      color: theme.palette.text.primary,

      "&:hover": {
        background: "none",
        textDecoration: "underline"
      }
    },

    connectButton: {
      "--aug-bl": "7px"
    },

    connectButtonColored: {
      "--aug-border-bg": theme.palette.primary.main,
      "& > p": {
        color: theme.palette.primary.main
      }
    },
    userIconButton: {
      transition: "none",
      borderRadius: 0,
      marginLeft: "0.5rem",
      height: "32px",
      width: "32px",
      cursor: "pointer",
      "--aug-border-all": "1px",
      "--aug-br": "7px",
      "--aug-tl": "7px",
      "--aug-border-bg": theme.palette.text.primary,
      "&:hover": {
        "--aug-border-bg": theme.palette.primary.main
      }
    },
    userIconButtonColored: {
      "--aug-border-bg": theme.palette.primary.main
    }
  };
});

const Navbar = () => {
  const classes = useStyles();
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { account, active } = useWeb3React();
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [anchorElWal, setAnchorElWal] = useState(null);
  const [underlineStyle, setUnderlineStyle] = useState(false);
  const [isWalletPopoverOpen, setIsWalletPopoverOpen] = useState(false);
  const triedEagerLogin = useEagerConnect();
  useInactiveListener(!triedEagerLogin);

  function handleWalletPopover(event, status) {
    if (status) setAnchorElWal(status ? event.currentTarget : null);
    setIsWalletPopoverOpen(status);
  }
  function handleAccountPopover(event, status) {
    if (status) setAnchorElAccount(status ? event.currentTarget : null);
    setAccountPopoverOpen(status);
  }

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "space-between"
      }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
        <AppBar sx={appBarSX} position="static">
          <Container sx={{ ml: 2, pr: 0 }} maxWidth="xl">
            <Toolbar style={{ height: "53px", minHeight: "53px" }} disableGutters>
              <Box
                component="img"
                onClick={() => window.open("https://delysium.com/", "_blank")}
                src={CDN_ASSETS.LOGO}
                sx={{
                  cursor: "pointer",
                  mr: "25px",
                  width: "108px",
                  display: "flex"
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  height: "39px",
                  alignItems: "center"
                }}>
                {PAGES.map(({ title, url = "#", sublists = false }, index) =>
                  sublists ? (
                    <CommunityHoverDropdown
                      onOpen={() => setUnderlineStyle(true)}
                      onClose={() => setUnderlineStyle(false)}
                      key={index}
                      title={
                        <Box sx={{ display: "flex", flexFlow: "row nowrap", columnGap: 9.75 }}>
                          {Object.entries(sublists).map(([listTitle, items]) => {
                            return (
                              <Box sx={{ display: "flex", flexFlow: "column nowrap" }}>
                                <Typography
                                  mb={0.75}
                                  lineHeight="18px"
                                  variant="body2"
                                  color={alpha("#FFF", 0.6)}>
                                  {listTitle}
                                </Typography>
                                {items.map((item, i) => (
                                  <Box
                                    key={i}
                                    component={item?.url ? "a" : "span"}
                                    href={item.url}
                                    target="_blank"
                                    sx={{ textDecoration: "none", "&:last-child": { mb: 2 } }}
                                    onClick={() => ReactGA.event(`enter_${item.title}`)}>
                                    <MenuItem disableRipple className={classes.dropdownMenuItem}>
                                      {item.title}
                                    </MenuItem>
                                  </Box>
                                ))}
                              </Box>
                            );
                          })}
                        </Box>
                      }>
                      <Box
                        role="button"
                        key={index}
                        className={clsx(classes.navLink, underlineStyle && classes.activeNavLink)}
                        sx={{
                          height: "100%",
                          fontSize: 14,
                          fontWeight: 400,
                          padding: "6px 15px",
                          cursor: "pointer"
                        }}>
                        {title}
                      </Box>
                    </CommunityHoverDropdown>
                  ) : (
                    <NavLink
                      exact
                      isActive={(match, location) => {
                        if (location.pathname.includes("/nft/") && title === "marketplace") {
                          return true;
                        } else return match?.path.match(location.pathname);
                      }}
                      to={url}
                      key={index}
                      className={clsx(classes.navLink)}
                      activeClassName={classes.activeNavLink}>
                      <Button disableRipple>{title}</Button>
                    </NavLink>
                  )
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box
          maxWidth="xl"
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            flexGrow: 0,
            zIndex: (theme) => theme.zIndex.modal + 1,
            pr: 3,
            mt: "18px"
          }}>
          {active && (
            <Button
              disableRipple
              sx={buttonSX}
              variant="outlined"
              color="inherit"
              data-augmented-ui="bl-clip border"
              startIcon={isWalletPopoverOpen ? <WalletIcon /> : <WalletWhiteIcon />}
              className={clsx(
                classes.connectButton,
                isWalletPopoverOpen && classes.connectButtonColored
              )}
              onClick={(e) => handleWalletPopover(e, true)}
              onMouseOver={(e) => handleWalletPopover(e, true)}>
              <Typography sx={{ textTransform: "initial" }} color="textPrimary">
                {account?.substr(0, 6)}...{account?.substr(account?.length - 4, 4)}
              </Typography>
            </Button>
          )}

          {isAuthenticated ? (
            <IconButton
              disableRipple
              variant="outlined"
              color="inherit"
              size="small"
              data-augmented-ui="tl-clip br-clip border"
              className={clsx(
                classes.userIconButton,
                accountPopoverOpen && classes.userIconButtonColored
              )}
              onClick={(e) => handleAccountPopover(e, true)}
              onMouseOver={(e) => handleAccountPopover(e, true)}>
              {accountPopoverOpen ? <UserIcon /> : <UserWhiteIcon />}
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              sx={buttonSX}
              data-augmented-ui="tl-clip br-clip border"
              className={classes.connectButton}
              disableRipple
              onClick={(e) => {
                ReactGA.event(`enter_user_dashboard`);
                window.open(PATH_EXTERNAL.sso.login, "_self");
              }}>
              <Typography color="textPrimary">SIGN IN</Typography>
            </Button>
          )}

          <AccountPopover
            isOpen={accountPopoverOpen}
            anchorEl={anchorElAccount}
            handleClose={() => handleAccountPopover(null, false)}
          />

          <WalletPopover
            isWalletOpen={active && isWalletPopoverOpen}
            anchorElWal={anchorElWal}
            handleClose={() => handleWalletPopover(null, false)}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default Navbar;
