import { PATH_ERROR } from "./paths";
import NFTLayout from "../layouts/NFTLayout";
import PageNotFound from "../views/Error/NotFound";

// ----------------------------------------------------------------------

const ErrorRoutes = {
  path: PATH_ERROR.NOT_FOUND,
  layout: NFTLayout,
  component: () => <PageNotFound />,
  exact: true
};

export default ErrorRoutes;
