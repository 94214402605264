import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NFT_VIEW_SPACING } from "../constants/styles";

const Copyright = ({ textProps, ...props }) => {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      {...props}
      px={NFT_VIEW_SPACING.CONTENT_PADDING}
      sx={{ opacity: 0.6, width: "100%", my: 0.5, ...props?.sx }}>
      <Typography
        component="p"
        variant="caption"
        color="textSecondary"
        fontWeight={400}
        {...textProps}>
        Copyright © {currentYear} Delysium. All rights reserved.
      </Typography>
    </Box>
  );
};
export default Copyright;
