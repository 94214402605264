import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { alpha, Grid, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import CustomSelect from "../../components/Select";
import { NFTRarities } from "../../utils/NFTs";
import { useSelector } from "react-redux";
import { filterNFTs, searchNFTs } from "../../redux/slices/NFT";
import useBreakpoints from "../../hooks/useBreakpoints";
import { motion, AnimatePresence } from "framer-motion";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";
import ReactGA from "react-ga4";

const alertHeight = 38;
const offSet = 24;

const ALL_RARITIES = { title: "all rarities", value: "all" };
const modifiedRarities = [
  ALL_RARITIES,
  ...NFTRarities.map((item) => {
    return { icon: item.Icon, hoverIcon: item.HoverIcon, value: item.title };
  })
];

const containerSX = {
  justifyContent: "flex-start",
  flexFlow: "row nowrap",
  position: "sticky",
  left: 0,
  pl: { xs: 0, md: 1 },
  pr: { xs: 0, md: 3 },
  ml: 0,
  mt: { xs: 1, md: 0 },
  zIndex: (theme) => theme.zIndex.appBar,
  background: "#000"
};

const Filters = ({ ...props }) => {
  const dispatch = useDispatch();
  const downMd = useBreakpoints("down", "md");
  const isWrongNetwork = useIsWrongNetwork();
  const [isSearching, setIsSearching] = useState(false);
  const { searchedNFT, filters } = useSelector((state) => state.NFT);

  function timeoutAnimation() {
    return setTimeout(() => {
      setIsSearching(false);
    }, 0);
  }

  return (
    <Grid
      container
      columnSpacing={downMd ? 0 : 2}
      sx={{
        ...containerSX,
        top: `calc(5.5rem + ${isWrongNetwork ? `${alertHeight + offSet}px` : "0px"})`,
        ...props?.sx
      }}>
      <Grid
        item
        xs={downMd && isSearching ? 12 : 6}
        md={9}
        sx={{
          transition: `all 0.3s ease-in-out`,
          transitionDelay: downMd && isSearching ? "0.1s" : "0"
        }}>
        <TextField
          value={searchedNFT}
          onChange={(e) => {
            if (searchedNFT.length === 0) ReactGA.event(`type_in_search`);
            dispatch(searchNFTs(e.target.value));
          }}
          variant="outlined"
          placeholder="Search"
          size="small"
          onFocus={() => {
            ReactGA.event(`enter_search_mode`);
            setIsSearching(true);
          }}
          onBlur={() => timeoutAnimation()}
          autoComplete="off"
          color="secondary"
          sx={{ width: "100%" }}
          InputProps={{
            sx: { height: { xs: "32px", md: "initial" } },
            "data-augmented-ui": "tl-clip br-clip border",
            className: searchedNFT.length > 0 && "hasInput",
            startAdornment: (
              <InputAdornment position="end">
                <Search
                  sx={{
                    mr: 2,
                    fontSize: 20,
                    color: (theme) => alpha(theme.palette.text.secondary, 0.5)
                  }}
                />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <AnimatePresence initial={false}>
        {!(downMd && isSearching) && (
          <Grid
            animate={{
              opacity: 1,
              transition: {
                duration: 0.1,
                ease: [0.43, 0.13, 0.23, 0.96]
              }
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.1,
                ease: [0.43, 0.13, 0.23, 0.96]
              }
            }}
            sx={{ transformOrigin: "right", ml: { xs: 0.5, md: 0 } }}
            component={motion.div}
            item
            xs={6}
            md={3}>
            <CustomSelect
              value={filters.rarity}
              label="rarity"
              color="secondary"
              onChange={(event) => {
                dispatch(filterNFTs({ rarity: event.target.value }));
              }}
              options={modifiedRarities}
              MenuProps={{
                sx: { mt: 1 }
              }}
            />
          </Grid>
        )}
      </AnimatePresence>
    </Grid>
  );
};

export default Filters;
