// ----------------------------------------------------------------------

export default function Badge({ theme }) {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 8,
          height: 8,
          right: 10,
          zIndex: 10,
          top: 0
        }
      }
    }
  };
}
