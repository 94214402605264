import { Popover } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
// ----------------------------------------------------------------------

CustomPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      // minHeight: "10rem",
      minWidth: "5rem",
      background: theme.palette.background.default,
      backgroundImage: "initial",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      overflow: "visible",

      "--aug-border-all": "0px",
      "--aug-border-top": "0.5px",
      "--aug-border-bg": theme.palette.primary.main,
      "--aug-bl": "10px",
      "--aug-bl-inset1": "calc(var(--aug-bl1) * 3)",
      "--aug-b-extend1": "50%",
      "& button": {
        marginBottom: "20px",
        padding: "5px 12px",
        fontWeight: 400,
        fontSize: "14px",
        width: "127px",
        height: "28px",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
          width: "113px",
          height: "26px"
        }
      }
    }
  };
});

function CustomPopover({ open, anchorEl, onClose, children, height = "164px", ...props }) {
  const classes = useStyles();
  return (
    <Popover
      disableAutoFocus
      disableEnforceFocus
      {...props}
      PaperProps={{
        "data-augmented-ui": " bl-clip-y br-clip border",
        className: classes.paper,
        sx: {
          ...props.PaperProps?.sx,
          px: 2,
          py: 2.5,
          mt: 1,
          height: height,
          width: { xs: "153px", md: "167px" }
        },
        onMouseLeave: onClose
      }}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}>
      {children}
    </Popover>
  );
}
export default CustomPopover;
