import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  themeMode: "dark"
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    switchMode(state, action) {
      state.themeMode = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { switchMode } = slice.actions;
