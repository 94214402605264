import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import InfoBox from "../../components/InfoBox";
import CustomDivider from "../../components/Divider";
import useBreakpoints from "../../hooks/useBreakpoints";
import { getRarityColor } from "../../utils/NFTs";
import { ReactComponent as ETHBlueLogo } from "../../assets/icons/eth-blue.svg";
import { SALE_STAGE, SALE_MODE, useSaleCases } from "../../hooks/useSaleCases";
import LoadingView from "../ProductLoadingView";
import { NFT_VIEW_SPACING as SPACING } from "../../constants/styles";
import CopyrightView from "../../components/Copyright";
import { Helmet } from "react-helmet";
import axios from "axios";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import { ReactComponent as CheckMark } from "../../assets/icons/check-mark.svg";
import { ReactComponent as RareNFTBadge } from "../../assets/rare-nft-badge.svg";
import { ReactComponent as SBTBadge } from "../../assets/sbt-badge.svg";
import { ReactComponent as USDCBadge } from "../../assets/usdc-badge.svg";
import { ReactComponent as RoundQuestionMark } from "../../assets/icons/question_mark_round.svg";
import { makeStyles } from "@mui/styles";
import ReactGA from "react-ga4";
import { config } from "../../config";

const SHARED_PADDING_STYLE = {
  px: { md: SPACING.CONTENT_PADDING },
  pt: { md: SPACING.CONTENT_PADDING }
};

const LeaderboardBar = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "space-between",
        my: 1.5,
        py: 1.125,
        px: { xs: 1, md: 3.75 },
        background: (theme) => alpha(theme.palette.text.primary, 0.1)
      }}>
      {children}
    </Box>
  );
};
const HighlightedBox = ({ children, ...props }) => {
  return (
    <Box
      data-augmented-ui="br-clip"
      {...props}
      sx={{
        background: (theme) => alpha(theme.palette.text.primary, 0.1),
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        ...props.sx
      }}>
      {children}
    </Box>
  );
};

const InfoItems = ({ infoObject, infoTitle }) => {
  return infoObject ? (
    <React.Fragment>
      <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />
      <Box sx={{ mt: { xs: 2 }, ...SHARED_PADDING_STYLE }}>
        <Typography
          sx={{ mb: { md: 2.5 }, fontSize: "16px", textTransform: "capitalize" }}
          color="primary">
          {infoTitle}
        </Typography>

        <Grid container columnSpacing={3} rowSpacing={1} mt={1.25} width="100%">
          {Object.keys(infoObject).map((subItemKey, index) => {
            return (
              <Grid key={index} item xs={6} md={4}>
                <InfoBox key={subItemKey} title={subItemKey} subtitle={infoObject[subItemKey]} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  ) : null;
};

const RarityTag = ({ rarity }) => {
  return (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end"
        }}
        variant="body2"
        color="text.secondary"
        component="div"></Typography>

      <Typography
        fontWeight={900}
        letterSpacing={2}
        fontSize={16}
        mt={0.5}
        component="h5"
        textTransform="uppercase"
        sx={{ color: getRarityColor(rarity) }}>
        ({rarity})
      </Typography>
    </>
  );
};

const QuestInfo = ({ title, subtitle, tasks = [] }) => {
  const iconStyles = {
    marginRight: "0.5rem",
    marginTop: "1px",
    minWidth: "16px",
    maxWidth: "16px"
  };

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{ fontSize: { xs: "1rem", md: "1.125rem" }, mb: { md: 2.5 } }}
        color="primary">
        {title}
      </Typography>
      <Typography
        sx={{ mb: { xs: 1, md: 2 }, fontSize: { xs: "0.75rem", md: "1rem" } }}
        variant="h5">
        {subtitle}
      </Typography>
      {tasks.map(({ title, status }, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              mt: { xs: 0.5, md: 2 }
            }}>
            {status ? <CheckMark style={iconStyles} /> : <Cancel style={iconStyles} />}
            <Typography
              variant="body1"
              color="textPrimary"
              dangerouslySetInnerHTML={{ __html: title }}
              key={index}
              sx={{
                fontSize: { xs: "12px", md: "16px" },
                "& > b": {
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: "normal"
                }
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const CountdownView = ({ isIMXSaleEnded, timeLeft, isWaiting, isPreSale }) => {
  const [days, hours, minutes, seconds] = timeLeft;

  if (isIMXSaleEnded) {
    return null;
  }

  function Placeholder({ children }) {
    return (
      <Typography variant="caption" color="primary">
        {" "}
        {children}{" "}
      </Typography>
    );
  }

  return (
    <Box display="flex" mt={1}>
      <Typography variant="caption" color="textSecondary">
        {/* Render units in singular or plural form */}
        {isPreSale ? "Pre-Sale" : "Official Sale"}
        &nbsp;{isWaiting ? "Starts" : "Ends"} in
        <Placeholder>{days}</Placeholder>
        Days,
        <Placeholder>{hours}</Placeholder>
        Hours,
        <Placeholder>{minutes}</Placeholder>
        Mins,
        <Placeholder>{seconds}</Placeholder>
        Secs
      </Typography>
    </Box>
  );
};

const buyButtonSX = {
  display: "flex",
  flexFlow: "column nowrap",
  textTransform: "uppercase",
  width: { xs: "100%", md: "70%" },
  maxWidth: { xs: "initial", md: 356 },
  mr: 1,
  my: { xs: 1, md: 0 },
  fontSize: 16
};

const imageContainerSX = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gridColumnStart: 0,
  gridColumnEnd: 1,
  mt: 3.75,
  pr: { md: 4 }
};

const imageSX = {
  height: "auto",
  width: "100%",
  maxHeight: "70vh",
  minHeight: { md: "70vh" },
  backgroundPosition: "center",
  backgroundSize: "104px auto"
};

const buttonContainerSX = {
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: { xs: "100%", md: "90%" },
  flexFlow: "row nowrap",
  mt: { xs: 1, md: 2.5 }
};

const useStyles = makeStyles((theme) => {
  return {
    typeSelection: {
      "--aug-border-all": "1px",
      "--aug-border-bg": alpha(theme.palette.text.secondary, 0.3),
      "--aug-tr1": "3px",
      "--aug-tr-inset2": "calc(var(--aug-tr1) * 3)",
      "&:hover": {
        "--aug-border-all": "1px",
        "--aug-border-bg": theme.palette.primary.main
      }
    },
    selectedType: {
      "--aug-border-all": "1px",
      "--aug-border-bg": theme.palette.primary.main
    },
    purchaseGuide: {
      padding: "8px",
      "--aug-border-all": "2px",
      "--aug-border-bg": alpha(theme.palette.text.secondary, 0.3),
      color: alpha(theme.palette.text.secondary, 0.3)
    }
  };
});

const TimelineSection = () => {
  return (
    <>
      <CustomDivider />
      <Box
        sx={{
          ...SHARED_PADDING_STYLE,
          width: "100%",
          mt: { xs: 1, md: 0 },
          mb: { xs: 2, md: 10 }
        }}>
        <Typography
          sx={{ mb: { xs: 1.5, md: 2.5 }, fontWeight: 600, fontSize: 20 }}
          component="h3"
          color="primary">
          Event Timeline
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexFlow: { xs: "row wrap", md: "row nowrap" }
          }}>
          <HighlightedBox
            sx={{
              width: { xs: "45%", md: "30%" },
              height: { xs: "177px", md: "202px" },
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "space-between",
              justifyContent: "center",
              px: 1.5,
              py: 3.75
            }}>
            <Typography flexGrow={1} color="primary" variant="h6">
              Sep 24 - 30
            </Typography>
            <Typography flexGrow={1} color="textPrimary" variant="body2">
              UniX NFT Sale Starts
            </Typography>
            <Typography flexGrow={1} color="textSecondary" variant="caption">
              On IMX, Rarible, and Delysium Marketplace
            </Typography>
          </HighlightedBox>

          <HighlightedBox
            sx={{
              width: { xs: "45%", md: "30%" },
              height: { xs: "177px", md: "202px" },
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "space-between",
              justifyContent: "center",
              px: 1.5,
              py: 3.75
            }}>
            <Typography flexGrow={1} color="primary" variant="h6">
              Oct 1
            </Typography>
            <Typography flexGrow={1} color="textPrimary" variant="body2">
              Delysium Pre-Season goes live
            </Typography>
            <Typography flexGrow={1} color="textSecondary" variant="caption">
              Runs 24/7 for one month
            </Typography>
          </HighlightedBox>

          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexFlow: { xs: "row nowrap", md: "column nowrap" },
              justifyContent: "space-between",
              alignItems: "flex-start"
            }}>
            <HighlightedBox
              sx={{
                width: { xs: "45%", md: "100%" },
                height: { xs: "177px", md: "45%" },
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "space-between",
                justifyContent: { xs: "start", md: "center" },
                p: 1.5
              }}>
              <Typography sx={{ flexGrow: { md: 1 } }} color="primary" variant="h6">
                Oct 1 – 21
              </Typography>
              <Typography sx={{ flexGrow: { md: 1 } }} color="textPrimary" variant="body2">
                UniX Carnival
              </Typography>
            </HighlightedBox>
            <HighlightedBox
              sx={{
                width: { xs: "45%", md: "100%" },
                height: { xs: "177px", md: "45%" },
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "space-between",
                justifyContent: { xs: "start", md: "center" },
                p: 1.5
              }}>
              <Typography sx={{ flexGrow: { md: 1 } }} color="primary" variant="h6">
                Oct 22 – 28
              </Typography>
              <Typography sx={{ flexGrow: { md: 1 } }} color="textPrimary" variant="body2">
                Reward validation and distribution
              </Typography>
            </HighlightedBox>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const QuestSection = ({ NFTQuests = [] }) => {
  return NFTQuests.map((quest, index) => (
    <>
      <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />
      <Box sx={{ ...SHARED_PADDING_STYLE, mt: { xs: 1, md: 0 }, mb: { xs: 2, md: 10 } }}>
        <QuestInfo
          key={index}
          title={quest.title}
          subtitle={quest.description}
          tasks={quest.tasks}
        />
      </Box>
    </>
  ));
};

const RewardSection = () => {
  return (
    <>
      <CustomDivider />

      <Box
        sx={{
          ...SHARED_PADDING_STYLE,
          width: "100%",
          mt: { xs: 1, md: 0 },
          mb: { xs: 2, md: 10 }
        }}>
        <Typography
          sx={{ mb: { xs: 1.5, md: 2.5 }, fontWeight: 600, fontSize: 20 }}
          component="h3"
          color="primary">
          Reward
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "stretch",
            justifyContent: "space-between"
          }}>
          <HighlightedBox
            sx={{
              pb: 5,
              width: { xs: "45%", md: "30%" },
              height: { xs: "177px", md: "202px" }
            }}>
            <USDCBadge style={{ minWidth: "50px", height: "auto" }} />
            <Typography variant="body1">
              Up to{" "}
              <Typography component="span" variant="body1" color="primary">
                1105 USDC
              </Typography>
            </Typography>
          </HighlightedBox>

          <HighlightedBox
            sx={{
              pb: 5,
              width: { xs: "45%", md: "30%" },
              height: { xs: "177px", md: "202px" }
            }}>
            <RareNFTBadge style={{ minWidth: "50px", height: "auto" }} />
            <Typography variant="body1">Rare Rifle NFT</Typography>
          </HighlightedBox>

          <HighlightedBox
            sx={{
              pb: 5,
              mt: { xs: 2, md: 0 },
              width: { xs: "45%", md: "30%" },
              height: { xs: "177px", md: "202px" }
            }}>
            <SBTBadge style={{ minWidth: "50px", height: "auto" }} />
            <Typography variant="body1">SBT and OAT Badges</Typography>
          </HighlightedBox>
        </Box>
      </Box>
    </>
  );
};

const LeaderboardSection = () => {
  const downMd = useBreakpoints("down", "md");
  const tableCellSX = {
    width: "30%",
    fontSize: { xs: "0.875rem", md: "1rem" },
    fontWeight: 500
  };
  return (
    <>
      <CustomDivider sx={{ display: { xs: "none", md: "flex" } }} />
      <Box sx={{ ...SHARED_PADDING_STYLE, width: "95%", mt: { xs: 1, md: 0 }, mb: { md: 10 } }}>
        <Typography
          variant="h5"
          sx={{ fontSize: { xs: "0.875rem", md: "1rem" }, mb: { md: 2.5 } }}
          color="primary">
          {downMd ? "Grand Master Fund Calculation Guide" : "Quest C Cash Reward Calculation Guide"}
        </Typography>

        <LeaderboardBar>
          <Typography sx={tableCellSX}>Leaderboard</Typography>
          <Typography sx={tableCellSX}>Without UniX Suit</Typography>
          <Typography sx={tableCellSX} color="primary">
            With UniX Suit
          </Typography>
        </LeaderboardBar>

        <LeaderboardBar>
          <Typography sx={tableCellSX}>Rank 1</Typography>
          <Typography sx={tableCellSX}>500 USDC</Typography>
          <Typography sx={tableCellSX} color="primary">
            1000 USDC
          </Typography>
        </LeaderboardBar>

        <LeaderboardBar>
          <Typography sx={tableCellSX}>Rank 2</Typography>
          <Typography sx={tableCellSX}>250 USDC</Typography>
          <Typography sx={tableCellSX} color="primary">
            500 USDC
          </Typography>
        </LeaderboardBar>

        <LeaderboardBar>
          <Typography sx={tableCellSX}>Rank 3</Typography>
          <Typography sx={tableCellSX}>50 USDC</Typography>
          <Typography sx={tableCellSX} color="primary">
            100 USDC
          </Typography>
        </LeaderboardBar>

        <LeaderboardBar>
          <Typography sx={tableCellSX}>Rank 4 To 10</Typography>
          <Typography sx={tableCellSX}>5 USDC</Typography>
          <Typography sx={tableCellSX} color="primary">
            10 USDC
          </Typography>
        </LeaderboardBar>
      </Box>
    </>
  );
};

function TypeSelectionSection({ types = [], selectedNFT, setSelectedNFT }) {
  const classes = useStyles();

  const containerSX = {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  };

  const selectorButtonSX = {
    width: "52px",
    height: "64px",
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  };

  return (
    <>
      <CustomDivider sx={{ display: { xs: "none", md: "flex" }, mt: SPACING.DIVIDER_MARGIN }} />

      <Box sx={{ ...SHARED_PADDING_STYLE, my: { xs: 1.5, md: 0 } }}>
        <Typography
          sx={{ display: { xs: "none", md: "initial" }, mb: 0.625, fontSize: 16 }}
          component="h3"
          color="primary">
          Type
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: { xs: "center", md: "flex-start" },
            mt: 2
          }}>
          {types.map((type, index) => {
            return (
              <Box
                sx={{
                  ...containerSX,
                  mr: 3
                }}>
                <Box
                  data-augmented-ui="tr-clip-x border"
                  className={clsx(
                    classes.typeSelection,
                    selectedNFT === index && classes.selectedType
                  )}
                  onClick={() => setSelectedNFT(index)}
                  sx={{
                    ...selectorButtonSX,
                    backgroundImage: `url(${type.thumbnail})`
                  }}
                />
                <Typography variant="caption">{type.name}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

const IMXNFTView = () => {
  const videoRef = useRef(null);
  const downMd = useBreakpoints("down", "md");
  const [isLoading, setIsLoading] = useState(true);
  const [NFTQuests, setNFTQuests] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(0);
  const [collectionExternalLink, setCollectionExternalLink] = useState("");
  const [NFTInfo, setNFTInfo] = useState({
    title: "Delysium × Unix Suit NFT",
    saleStartTime: 1664046000,
    saleEndTime: 1664132340,
    isSaleDisabled: false,
    types: [
      {
        name: "Male",
        thumbnail: "https://storage.delysium.com/ui/marketplace/suitsales/suit_male_thumbnails.png",
        animationURL: "https://storage.delysium.com/ui/marketplace/suitsales/suit_male.mp4"
      },
      {
        name: "Female",
        thumbnail:
          "https://storage.delysium.com/ui/marketplace/suitsales/suit_female_thumbnails.png",
        animationURL: "https://storage.delysium.com/ui/marketplace/suitsales/suit_female.mp4"
      }
    ],
    properties: {
      category: "Outfits",
      rarity: "Epic",
      type: "Suit",
      total_supply: 1000
    }
  });

  const hasVideo = NFTInfo.types[selectedNFT]?.animationURL ? true : false;

  const { timeLeft, currentSaleStage, currentSaleMode } = useSaleCases({
    preSaleStartDate: 0,
    preSaleEndDate: 0,
    publicSaleStartDate: NFTInfo.saleStartTime * 1000,
    publicSaleEndDate: NFTInfo.saleEndTime * 1000
  });

  // Stage alias
  const isWaitingPreSale = currentSaleStage === SALE_STAGE.WAITING_PRE_SALE;
  const isPreSale = currentSaleStage === SALE_STAGE.PRE_SALE;
  const isWaitingPublicSale = currentSaleStage === SALE_STAGE.WAITING_PUBLIC_SALE;
  const isIMXSaleEnded = currentSaleStage === SALE_STAGE.OPENSEA_SALE;

  // Computed
  const buyButtonText = isIMXSaleEnded ? "Sold Out" : "Buy on ImmutableX";

  const isDisabledBuyButtonConds = [
    () => isPreSale,
    () => isWaitingPreSale || isWaitingPublicSale,
    () => isLoading,
    () => NFTInfo.isSaleDisabled,
    () => isIMXSaleEnded
  ];

  // Mode alias
  const isSaleDisabledMode = currentSaleMode === SALE_MODE.DISABLED;

  function BuySection() {
    const classes = useStyles();
    const downMd = useBreakpoints("down", "md");
    const [showGuide, setShowGuide] = useState(false);
    const IMX_PURCHASE_GUIDE_LINK =
      "https://delysium.gitbook.io/delysium/start-playtesting/marketplace-guide/how-to-make-a-purchase-on-imx";

    return (
      !isSaleDisabledMode && (
        <>
          <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />

          <Box sx={{ ...SHARED_PADDING_STYLE, my: { xs: 1, md: 0 } }}>
            <>
              <Typography sx={{ mb: { md: 0.625 } }} component="h3" fontSize={16} color="primary">
                Price
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
                <ETHBlueLogo width={20} height={20} style={{ marginRight: "0.5rem" }} />
                <Typography color="primary" variant="h4" component="p">
                  0.075 ETH
                </Typography>
              </Box>
            </>

            <Box sx={buttonContainerSX}>
              <Button
                disableRipple
                data-augmented-ui="tl-clip br-clip"
                variant="contained"
                color="primary"
                size="large"
                disabled={isDisabledBuyButtonConds.some((fn) => fn())}
                onClick={() => {
                  ReactGA.event("click_buy_on_imx");
                  window.open(collectionExternalLink);
                }}
                sx={buyButtonSX}>
                {buyButtonText}
              </Button>
              <IconButton
                onClick={() => window.open(IMX_PURCHASE_GUIDE_LINK)}
                onMouseLeave={() => {
                  !downMd && setShowGuide(false);
                }}
                onMouseOver={() => {
                  !downMd && setShowGuide(true);
                }}
                sx={{ p: 0, ml: 2 }}
                disableRipple>
                <RoundQuestionMark />
              </IconButton>
              {showGuide && (
                <Button
                  className={classes.purchaseGuide}
                  size="small"
                  data-augmented-ui="tl-clip br-clip border"
                  sx={{
                    position: "absolute",
                    right: "-16px",
                    top: "50%",
                    whiteSpace: "nowrap",
                    padding: "10px"
                  }}>
                  Purchase Guide
                </Button>
              )}
            </Box>

            <CountdownView
              isWaiting={isWaitingPreSale || isWaitingPublicSale}
              timeLeft={timeLeft}
              isPreSale={isWaitingPreSale || isPreSale}
              isIMXSaleEnded={isIMXSaleEnded}
            />
          </Box>
        </>
      )
    );
  }

  useEffect(() => {
    const { current: videoElement } = videoRef;
    videoElement?.setAttribute("muted", "");
  }, []);

  useEffect(() => {
    axios
      .get(config.SUIT_ARK_CONFIG_URL)
      .then(({ data }) => {
        setNFTQuests(data.quests);
        setNFTInfo(data.info);
        setCollectionExternalLink(data.collectionExternalLink);
      })
      .catch((err) => console.log(err));
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Grid
      container
      sx={{
        display: "grid",
        px: 0,
        position: "relative",
        gridTemplateColumns: {
          xs: "100%",
          md: "50% 50%"
        }
      }}>
      <Helmet>
        <title>{`${NFTInfo.title} - Delysium Marketplace`}</title>
      </Helmet>

      <Grid item sx={{ position: "relative" }}>
        <Box sx={{ display: { md: "none" }, my: 1.875 }}>
          <Typography textTransform="uppercase" fontSize={24} fontWeight="bold" component="h1">
            {NFTInfo.title}
          </Typography>
          <RarityTag rarity={NFTInfo.properties?.rarity} />
        </Box>

        <Box sx={imageContainerSX}>
          <CustomDivider />
          <Box
            loop
            autoPlay
            muted
            playsInline
            controls={false}
            ref={videoRef}
            component={hasVideo ? "video" : "img"}
            src={NFTInfo.types[selectedNFT]?.animationURL}
            alt={NFTInfo.title}
            sx={{ ...imageSX }}
          />
        </Box>

        {downMd && (
          <>
            <TypeSelectionSection
              types={NFTInfo.types}
              setSelectedNFT={setSelectedNFT}
              selectedNFT={selectedNFT}
            />
            <BuySection />
            <InfoItems infoObject={NFTInfo.properties} infoTitle="properties" />
            <TimelineSection />
            <RewardSection />
            <QuestSection NFTQuests={NFTQuests} />
            <LeaderboardSection />
            <CopyrightView
              sx={{ textAlign: "center" }}
              textProps={{ sx: { display: "initial", margin: "0 auto" } }}
            />
          </>
        )}

        {!downMd && (
          <>
            <TimelineSection />
            <RewardSection />
            <LeaderboardSection />
            <CustomDivider />
            <CopyrightView
              sx={{ textAlign: "left" }}
              textProps={{ sx: { display: "initial", margin: "0 auto" } }}
            />
          </>
        )}
      </Grid>
      {!downMd && (
        <Grid item sx={{ pl: { md: 4 } }}>
          <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />
          <Box
            sx={{
              flexFlow: "column nowrap",
              ...SHARED_PADDING_STYLE
            }}>
            <Typography
              sx={{ textTransform: "uppercase", fontSize: 28, fontWeight: "bold" }}
              component="h1">
              {NFTInfo.title}
            </Typography>

            <RarityTag rarity={NFTInfo.properties?.rarity} />
          </Box>

          {!downMd && (
            <>
              <TypeSelectionSection
                types={NFTInfo.types}
                setSelectedNFT={setSelectedNFT}
                selectedNFT={selectedNFT}
              />
              <BuySection />
              <InfoItems infoObject={NFTInfo.properties} infoTitle="properties" />
              <QuestSection NFTQuests={NFTQuests} />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default IMXNFTView;
