import React from "react";
import { Tooltip, alpha } from "@mui/material";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------

CustomTooltip.propTypes = {
  title: PropTypes.string
};

// ----------------------------------------------------------------------

const arrowSX = {
  fontSize: 16,
  width: 17,
  bottom: "-1px",
  transform: "translateX(-100%)",
  "&::before": {
    border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.7)}`,
    background: (theme) => theme.palette.background.default,
    boxSizing: "border-box"
  }
};

const tooltipSX = {
  border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.7)}`,
  borderRadius: 0,
  background: (theme) => theme.palette.background.default
};

// ----------------------------------------------------------------------

function CustomTooltip({ title, children, ...props }) {
  // const theme = useTheme();
  return (
    <Tooltip
      arrow
      placement="top-start"
      componentsProps={{
        popper: { sx: { zIndex: (theme) => theme.zIndex.appBar - 10 } },
        arrow: { sx: arrowSX },
        tooltip: {
          sx: tooltipSX
        }
      }}
      title={title}
      {...props}>
      {children}
    </Tooltip>
  );
}

export default CustomTooltip;
