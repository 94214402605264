import { PATH_DMA } from "./paths";
import NFTLayout from "../layouts/NFTLayout";
import DMAView from "../views/DMA";

// ----------------------------------------------------------------------

const DMARoutes = {
  path: PATH_DMA.root,
  layout: NFTLayout,
  component: () => <DMAView />
};

export default DMARoutes;
