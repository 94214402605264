import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const PAGE_SIZE = 20;

const initialState = {
  isLoading: false,
  paymentProcessing: false,
  searchedNFT: "",
  NFT: null,
  NFTs: [],
  hasError: false,

  pagination: {
    isLoadingMore: false,
    total_num: 0,
    page: 1,
    page_size: PAGE_SIZE,
    hasMore: true
  },

  filters: {
    rarity: "all",
    category: ""
  }
};

export const slice = createSlice({
  name: "NFT",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetPagination(state) {
      state.pagination = initialState.pagination;
    },
    setError(state, action) {
      state.isLoading = false;
      state.hasError = action.payload;
    },
    setPagination(state, action) {
      const { total_page, page } = action.payload;
      state.pagination = { ...action.payload, hasMore: total_page !== page };
    },
    setLoadingMore(state, action) {
      state.pagination.isLoadingMore = action.payload;
    },
    setLoading(state, action) {
      state.hasError = false;
      state.isLoading = action.payload;
    },
    searchNFTs(state, action) {
      state.searchedNFT = action.payload;
    },

    setPaymentProcess(state, action) {
      state.paymentProcessing = action.payload;
    },

    filterNFTs(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },

    addNFTNumSold(state, action) {
      const { on_chain_info } = state.NFT;
      const { NumSold } = on_chain_info;
      state.NFT = {
        ...state.NFT,
        on_chain_info: { ...on_chain_info, NumSold: NumSold + action.payload }
      };
    },

    emptyNFTs(state) {
      state.NFTs = [];
    },

    //For list of NFTs
    getNFTsSuccess(state, action) {
      state.isLoading = false;
      state.NFTs = action.payload;
    },
    //For a single NFT
    getNFTSuccess(state, action) {
      state.isLoading = false;
      state.NFT = action.payload;
    },
    getMoreNFTsSuccess(state, action) {
      state.pagination.isLoadingMore = false;
      state.NFTs = [...state.NFTs, ...action.payload];
    }
  }
});

export const {
  filterNFTs,
  emptyNFTs,
  setPaymentProcess,
  searchNFTs,
  setLoading,
  addNFTNumSold,
  setPage,
  resetPagination,
  hasError
} = slice.actions;

export function getNFTs(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await axios.get(`/product?page=1&page_size=${PAGE_SIZE}`, {
        params: filters
      });
      const { page, page_size, total_num, total_page, products } = data.data;
      dispatch(slice.actions.getNFTsSuccess(products));
      dispatch(slice.actions.setPagination({ page, page_size, total_num, total_page }));
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export function getMoreNFTs(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoadingMore(true));
    try {
      const { data } = await axios.get("/product", { params: filters });
      if (data?.data) {
        const { page, page_size, total_num, total_page, products } = data.data;
        dispatch(slice.actions.getMoreNFTsSuccess(products));
        dispatch(slice.actions.setPagination({ page, page_size, total_num, total_page }));
      } else dispatch(slice.actions.getMoreNFTsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.getMoreNFTsSuccess([]));
    }
  };
}

export function getOwnedNFTs({ walletAddress, filters }) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await axios.get(
        `/inventory?page=1&page_size=${PAGE_SIZE}&owned=${walletAddress}`,
        {
          params: filters
        }
      );
      if (data?.data) {
        const { page, page_size, total_num, total_page, products, total_num_owned } = data.data;
        dispatch(slice.actions.getNFTsSuccess(products));
        dispatch(
          slice.actions.setPagination({ page, page_size, total_num, total_page, total_num_owned })
        );
      } else dispatch(slice.actions.getNFTsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export function getMoreOwnedNFTs({ walletAddress, filters }) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoadingMore(true));
    try {
      const { data } = await axios.get(`/inventory?owned=${walletAddress}`, {
        params: filters
      });
      if (data?.data) {
        const { page, page_size, total_num, total_page, products, total_num_owned } = data.data;
        dispatch(slice.actions.getMoreNFTsSuccess(products));
        dispatch(
          slice.actions.setPagination({ page, page_size, total_num, total_page, total_num_owned })
        );
      } else dispatch(slice.actions.getMoreNFTsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.getMoreNFTsSuccess([]));
    }
  };
}

export function getOwnedNFT({ walletAddress, filters }) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await axios.get(`/inventory?page=1&page_size=1&owned=${walletAddress}`, {
        params: filters
      });
      const foundNFT = data?.data?.products[0] || null;
      if (foundNFT === null) {
        window.location = "/not-found";
        return;
      }
      dispatch(slice.actions.getNFTSuccess(foundNFT));
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export function getNFT({ modelId }) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await axios.get(`/product?page=1&page_size=1&model_id=${modelId}`);
      const foundNFT = data.data.products[0] || null;
      if (foundNFT === null) {
        window.location = "/not-found";
        return;
      }
      dispatch(slice.actions.getNFTSuccess(foundNFT));
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export default slice.reducer;
