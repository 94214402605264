import React, { useEffect } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import routes, { renderRoutes } from "./routes";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import ThemeConfig from "./theme";
import NotificationBar from "./components/NotificationBar";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import ScrollToTop from "./components/ScrollToTop";
import "./assets/augmented.css";
import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";
import { authUser, initializeUser } from "./redux/slices/auth";
import { injectStore } from "./utils/axios";
import WalletLoginDialog from "./layouts/Common/WalletLoginDialog";
import { SSO_TOKEN_NAME } from "./constants/cookies";
import { __PROD__, __STAGING__ } from "./utils/dev";

Sentry.init({
  dsn: "https://f28f96e18ca94baf9d38e30c6eefbddf@o4504161299136512.ingest.sentry.io/4504161301757954",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: __PROD__ ? 1.0 : __STAGING__ ? 0.5 : 0
});

// Setting Google Analytics up and tracking every page view
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
ReactGA.send("pageview");

// Injecting store for usage in the axios interceptor
injectStore(store);

function getLibrary(provider) {
  return new Web3Provider(provider, "any");
}

function App() {
  const [cookies] = useCookies([SSO_TOKEN_NAME]);
  const history = useHistory();

  useEffect(() => {
    const jwt = cookies[SSO_TOKEN_NAME];

    store.dispatch(authUser(jwt));
    store.dispatch(initializeUser(jwt));
  }, [cookies]);

  return (
    <Provider store={store}>
      <ThemeConfig>
        <BrowserRouter>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ScrollToTop />
            {renderRoutes(routes)}
            <WalletLoginDialog />
          </Web3ReactProvider>
        </BrowserRouter>
        <NotificationBar />
      </ThemeConfig>
    </Provider>
  );
}

export default App;
