//@TODO: refactor this and walletpopver to reusable one component
import React from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import CustomPopover from "../../components/Popover";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { PATH_EXTERNAL } from "../../routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/auth";

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object
};

// ----------------------------------------------------------------------

function CustomButton({ children, onClick, ...props }) {
  return (
    <Button
      disableRipple
      variant="outlined"
      color="primary"
      data-augmented-ui="tl-clip br-clip border"
      onClick={onClick}
      {...props}
      sx={{
        color: (theme) => theme.palette.primary.main,
        ...props?.sx
      }}>
      {children}
    </Button>
  );
}
function AccountPopover({ isOpen, anchorEl, handleClose }) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  return (
    <CustomPopover open={isOpen} anchorEl={anchorEl} onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <CustomButton
          onClick={() => {
            ReactGA.event(`enter_dashboard_setting`);
            window.location.assign(PATH_EXTERNAL.dashboard.settings);
          }}>
          Account Setting
        </CustomButton>

        <CustomButton
          onClick={() => {
            ReactGA.event(`enter_dashboard_dma`);
            window.location.assign(PATH_EXTERNAL.dashboard.dma);
          }}>
          DMA Rewards
        </CustomButton>

        <CustomButton
          color="secondary"
          sx={{ color: (theme) => theme.palette.secondary.main }}
          onClick={() => {
            ReactGA.event(`logout`);
            dispatch(logout(token));
            handleClose();
          }}>
          Logout
        </CustomButton>
      </Box>
    </CustomPopover>
  );
}

export default AccountPopover;
