import { ReactComponent as Common } from "../assets/icons/rarities/common.svg";
import { ReactComponent as Rare } from "../assets/icons/rarities/rare.svg";
import { ReactComponent as Legendary } from "../assets/icons/rarities/legendary.svg";
import { ReactComponent as Ultimate } from "../assets/icons/rarities/ultimate.svg";
import { ReactComponent as Epic } from "../assets/icons/rarities/epic.svg";
import { ReactComponent as CommonHover } from "../assets/icons/rarities/common-hover.svg";
import { ReactComponent as RareHover } from "../assets/icons/rarities/rare-hover.svg";
import { ReactComponent as LegendaryHover } from "../assets/icons/rarities/legendary-hover.svg";
import { ReactComponent as UltimateHover } from "../assets/icons/rarities/ultimate-hover.svg";
import { ReactComponent as EpicHover } from "../assets/icons/rarities/epic-hover.svg";

export const USDC_DIVIDER = 1000000;
export const CURRENCY = "USDC";
export const DMA_CURRENCY = "USDT";

export function getUSDCPrice(price) {
  return price / USDC_DIVIDER;
}
export const NFTCategories = [
  { title: "All Items", value: "" },
  { title: "Weapon", value: "Weapon" },
  { title: "Outfit", value: "Outfit" },
  { title: "Collectibles", value: "Collectible" },
  { title: "Cyberbody", value: "Cyberbody" },
  { title: "Battle Pass", value: "battlePass" }
];

export const NFTRarities = [
  {
    title: "Common",
    color: "#4AD479",
    Icon: () => <Common />,
    HoverIcon: () => <CommonHover />
  },
  {
    title: "Rare",
    color: "#3F73AF",
    Icon: () => <Rare />,
    HoverIcon: () => <RareHover />
  },
  {
    title: "Epic",
    color: "#B163FF",
    Icon: () => <Epic />,
    HoverIcon: () => <EpicHover />
  },
  {
    title: "Legendary",
    color: "#FF7C60",
    Icon: () => <Legendary />,
    HoverIcon: () => <LegendaryHover />
  },
  {
    title: "Ultimate",
    color: "#FFBB0B",
    Icon: () => <Ultimate />,
    HoverIcon: () => <UltimateHover />
  }
];

export function getRarityColor(rarity = "") {
  const foundRarity = NFTRarities.find(
    (item) => rarity.toLowerCase() === item.title.toLocaleLowerCase()
  );
  return foundRarity ? foundRarity.color : "#eee";
}

export function getRarityObject(rarity = "") {
  const foundNFT = NFTRarities.find(
    (item) => rarity.toLowerCase() === item.title.toLocaleLowerCase()
  );
  if (foundNFT) return foundNFT;
  return { Icon: () => <></> };
}
