import React from "react";
import { IconButton, Box } from "@mui/material";

const menuBarSX = {
  left: "20px",
  top: "4px",
  position: "relative",
  display: "inline-block",
  width: "21px",
  height: "21px",
  marginRight: "23px"
};

const BarSX = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "2px",
  backgroundColor: (theme) => theme.palette.text.primary,
  transition: "all 218ms",
  transform: "scaleY(0.8)",
  backfaceVisibility: "hidden"
};

const topBarSX = {
  ...BarSX,
  transformOrigin: "top",
  "&.isOpen": {
    transform: "rotate(45deg) translate(4px, 6px) scaleY(0.8)"
  }
};

const midBarSX = {
  ...BarSX,
  top: "6px",
  "&.isOpen": {
    backgroundColor: "transparent"
  }
};

const bottomBarSX = {
  ...BarSX,
  top: "12px",
  transformOrigin: "bottom",
  "&.isOpen": {
    transform: "rotate(-45deg) translate(3px, -5px) scaleY(0.8)"
  }
};

const BurgerToggleIcon = ({ onChange, open, ...props }) => {
  return (
    <IconButton onClick={onChange} sx={menuBarSX} {...props}>
      <Box className={open && "isOpen"} sx={topBarSX} />
      <Box className={open && "isOpen"} sx={midBarSX} />
      <Box className={open && "isOpen"} sx={bottomBarSX} />
    </IconButton>
  );
};

export default BurgerToggleIcon;
