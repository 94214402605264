import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TabGroup from "../../components/TabGroup";
import CustomSkeleton from "../../components/Skeleton";
import { filterNFTs } from "../../redux/slices/NFT";
import { NFTCategories } from "../../utils/NFTs";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      "--aug-tl": "10px",
      "--aug-border-all": "1px",
      "--aug-border-top": "1.5px",
      "--aug-border-bg": theme.palette.divider,
      "--aug-bl": "6px",
      "--aug-bl-inset1": "calc(var(--aug-bl1) * 14)",
      "--aug-b-extend1": "50%"
    }
  };
});

// ----------------------------------------------------------------------

const SkeletonTabGroup = () => {
  const rootStyle = {
    padding: "36px 22px 60px"
  };

  return (
    <Box sx={rootStyle}>
      {[...Array(5)].map((el, index) => (
        <CustomSkeleton key={index} mb={5} />
      ))}
    </Box>
  );
};

const FooterView = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ mt: 1.875, textAlign: "center", opacity: 0.6 }}>
      <Typography
        component="p"
        variant="caption"
        color="textSecondary"
        sx={{ display: { xs: "none", sm: "initial" }, fontWeight: 400 }}>
        Copyright © {currentYear} Delysium. <br /> All rights reserved.
      </Typography>
    </Box>
  );
};

const alertHeight = 38;
const offSet = 24;

// ----------------------------------------------------------------------

CategoryMenu.propTypes = {
  isInventory: PropTypes.bool,
  isSkeleton: PropTypes.bool
};

// ----------------------------------------------------------------------
export default function CategoryMenu({ isSkeleton = false, isInventory, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { NFTs, filters, pagination } = useSelector((state) => state.NFT);
  const [firstLoading, setFirstLoading] = useState(true);
  const isWrongNetwork = useIsWrongNetwork();
  const sidebarTopFormula = `
    5.5rem + ${isWrongNetwork ? `${alertHeight + offSet}px` : "0px"}
  `;

  const handleChange = (event, newValue) => {
    dispatch(filterNFTs({ category: newValue }));
  };

  useEffect(() => {
    setTimeout(() => {
      setFirstLoading(false);
    }, [1000]);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-between",
        position: "sticky",
        top: `calc(${sidebarTopFormula})`,
        minHeight: {
          xs: "initial",
          md: `calc(95vh - (${sidebarTopFormula}))`
        },
        width: "100%",
        zIndex: (theme) => theme.zIndex.appBar - 1
      }}>
      <Box
        className={classes.paper}
        data-augmented-ui="tl-clip bl-clip-y br-clip border"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexFlow: "column nowrap"
        }}>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "53px",
            p: 3,
            borderBottom: "1px solid",
            flexFlow: { sm: "row wrap", md: "row nowrap" },
            borderColor: "divider"
          }}>
          <Box display="flex">
            <Typography color="primary" variant="h6" fontWeight="400">
              {isSkeleton
                ? "Loading... "
                : pagination.total_num_owned
                ? pagination.total_num_owned
                : pagination.total_num}
            </Typography>
            <Typography
              sx={{ ml: 0.35 }}
              component="span"
              variant="h6"
              fontWeight="400"
              textTransform="capitalize">
              {!isSkeleton && NFTs?.length === 1 ? "Item" : "Items"}
            </Typography>
          </Box>
        </Box>

        {isSkeleton && firstLoading ? (
          <SkeletonTabGroup />
        ) : (
          <TabGroup
            items={NFTCategories}
            selectedItem={filters.category}
            onChange={handleChange}
            label="NFT Categories Menu"
          />
        )}
      </Box>
      <FooterView />
    </Box>
  );
}
