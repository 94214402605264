import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getCorrectChain } from "../utils/chains";

const useIsWrongNetwork = () => {
  const { chainId, library: provider } = useWeb3React();
  const { pathname } = useLocation();
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);

  useEffect(() => {
    if (provider) {
      provider?.getNetwork().then(() => {
        //Checks if we are on the DMA page, if so, the correct chains will be from mainnet, otherwise from polygon
        const correctChain = getCorrectChain(pathname);
        if (chainId !== correctChain.id) {
          setIsWrongNetwork(true);
        } else setIsWrongNetwork(false);
      });
    }
  }, [provider, chainId, pathname]);

  return isWrongNetwork;
};

export default useIsWrongNetwork;
