import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import { fullscreenCenterStyle } from "../../utils/styles";

const CDN_ASSETS = {
  NOT_FOUND_ILLUSTRATION: "https://storage.delysium.com/ui/404.svg"
};

const NotFound = () => {
  return (
    <Container sx={{ maxWidth: "1187px" }}>
      <Helmet>
        <title>Page Not Found - Delysium Marketplace</title>
      </Helmet>
      <Box sx={{ ...fullscreenCenterStyle("146px") }}>
        <Typography
          sx={{
            fontSize: { md: "24px", sm: "13px" },
            fontWeight: 400,
            lineHeight: 1.3,
            maxWidth: "650px"
          }}>
          Cannot process Passband for access into Delysium or what you are looking for does not
          exist...
        </Typography>
        <Box sx={{ mt: "10px", px: "20px", width: "100%", maxWidth: "620px" }}>
          <Box
            sx={{
              height: 0,
              paddingTop: "67.44639376218323%",
              backgroundImage: `url(${CDN_ASSETS.NOT_FOUND_ILLUSTRATION})`,
              backgroundSize: "contain",
              backgroundPosition: "50%"
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
