import React from "react";
import { Box, keyframes } from "@mui/system";

const dualRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = ({ size = "18", ...props }) => {
  return (
    <Box
      sx={{
        display: "inline-block",
        position: "relative",
        width: `${size}px`,
        height: `${size}px`,
        "& div": {
          position: "absolute",
          left: 0,
          top: 0,
          boxSizing: "border-box",
          display: "block",
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          borderColor: (theme) =>
            `${theme.palette.primary.main} transparent transparent transparent`,
          animation: `${dualRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`
        },
        "& div:nth-of-type(1)": {
          animationDelay: "-0.45s"
        },
        "& div:nth-of-type(2)": {
          animationDelay: "-0.3s"
        },
        "& div:nth-of-type(3)": {
          animationDelay: "-0.15s"
        },
        ...props?.sx
      }}>
      <Box /> <Box /> <Box /> <Box />
    </Box>
  );
};

export default LoadingSpinner;
