import { alpha } from "@mui/material";

export default function Button({ theme }) {
  const { primary, secondary, grey, action } = theme.palette;
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          transition: "backgroundColor ease 0.3s",
          fontWeight: "bold"
        },

        startIcon: {
          color: "inherit"
        },
        sizeLarge: {
          height: 51
        },
        sizeMedium: {
          height: 30
        },
        sizeSmall: {
          height: 26
        },

        //CONTAINED WITH PRIMARY COLOR
        containedPrimary: {
          color: grey[900],
          backgroundColor: alpha(primary.main, 0.9),
          "&:hover": {
            backgroundColor: action.hover
          },
          "&.Mui-disabled": {
            backgroundColor: alpha("#293230", 0.9),
            color: alpha("#BAC2C1", 0.2)
          }
        },

        outlinedPrimary: {
          color: primary.lighter,

          "&.Mui-disabled": {
            color: primary.lighter,
            // borderColor: primary.lighter,
            opacity: "0.7"
          }
        },

        //OUTLINED WITH SECONDARY COLOR
        outlinedSecondary: {
          color: secondary.light
          // borderColor: secondary.light,

          // "&:hover": {
          //   borderColor: secondary.main
          // }
        },

        //CONTAINED WITH INHERIT COLOR
        containedInherit: {
          color: grey[900],
          "&:hover": {
            backgroundColor: grey[400]
          }
        },

        // OUTLINED GENERAL STYLES
        outlined: {
          position: "relative",
          zIndex: 4,
          borderRadius: 0

          // "&:hover": {
          //   borderColor: "inherit"
          // }
        },

        textInherit: {
          "&:hover": {
            backgroundColor: action.hover
          }
        }
      }
    }
  };
}
