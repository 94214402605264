export default function Input({ theme }) {
  const { primary: textPrimary } = theme.palette.text;
  const { main } = theme.palette.primary;
  return {
    MuiFormLabel: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-focused": {
            color: main
          }
        },
        colorSecondary: {
          "&.Mui-focused": {
            color: textPrimary
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          position: "relative",
          caretColor: main,

          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {}
          }
        },

        colorPrimary: {
          color: main,

          "&.Mui-focused, &.Mui-disabled": {
            color: main,

            "& > input": {
              WebkitTextFillColor: "inherit"
            }
          },

          "& .MuiOutlinedInput-notchedOutline": {
            color: main
          }
        },

        colorSecondary: {
          color: theme.palette.text.primary,

          "&.Mui-focused": {
            color: textPrimary
          }
        }
      }
    }
  };
}
