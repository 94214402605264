// ----------------------------------------------------------------------

export default function IconButton({ theme }) {
  const { primary } = theme.palette;
  return {
    MuiIconButton: {
      variants: [{ props: { color: "default" } }, { props: { color: "inherit" } }],

      styleOverrides: {
        outlinedPrimary: {
          color: primary.lighter,

          "&.Mui-disabled": {
            color: primary.lighter,

            opacity: "0.7"
          }
        }
      }
    }
  };
}
