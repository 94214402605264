import { createSlice } from "@reduxjs/toolkit";
import { CORRECT_CHAIN } from "../../utils/chains";

const initialState = {
  correctChain: CORRECT_CHAIN,
  isWalletConnectionOpen: false
};

export const slice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletConnectionOpen(state, action) {
      state.isWalletConnectionOpen = action.payload;
    },
    setCorrectChain(state, action) {
      state.correctChain = action.payload;
    }
  }
});

export const { setWalletConnectionOpen } = slice.actions;

export default slice.reducer;
