export const LS_WALLET_ADDRESS = "dm_wallet_address";
export const LS_WALLET_TYPE = "dm_wallet_type";

export function getLocalStorage(key) {
  return window.localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
  return window.localStorage.setItem(key, value);
}
