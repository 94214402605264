import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Divider, Typography, Button } from "@mui/material";
import { ReactComponent as USDCLogo } from "../assets/icons/usdc_white.svg";
import { ReactComponent as USDCBlueLogo } from "../assets/icons/usdc_blue.svg";
import { Network, Marketplace } from "@delysium/client";
import NumberTextField from "./NumberTextfield";
import CustomDialog from "./Dialog";
import { CURRENCY, getUSDCPrice } from "../utils/NFTs";
import { useWeb3React } from "@web3-react/core";
import {
  NOTIF_FAIL,
  NOTIF_PROGRESSING,
  NOTIF_SUCCESS,
  setNotification
} from "../redux/slices/notifications";
import { addNFTNumSold, setPaymentProcess } from "../redux/slices/NFT";
import notifications from "../constants/notifications";
import LOADING_IMAGE_URL from "../assets/icons/processing-icon.gif";
import { PropTypes } from "prop-types";
import { __PROD__ } from "../utils/dev";

// ----------------------------------------------------------------------
CheckoutDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isPublicSale: PropTypes.bool,
  isPreSale: PropTypes.bool
};

// ----------------------------------------------------------------------

function StyledDivider() {
  return <Divider sx={{ width: "100%", margin: "0 auto", borderWidth: "1px" }} />;
}

const LoadingIconView = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  const styles = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "18px",
    height: "18px",
    backgroundImage: `url(${LOADING_IMAGE_URL})`,
    backgroundSize: "contain",
    mixBlendMode: "difference",
    marginRight: "7px"
  };

  return <Box sx={styles} />;
};

const MAX_PURCHASE_ALLOWED = 200;

function CheckoutDialog({ isOpen, setClose, isPublicSale }) {
  const { purchase } = notifications;
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const { NFT, paymentProcessing } = useSelector((state) => state.NFT);
  const { library: provider, active } = useWeb3React();
  const availableSupply = NFT?.on_chain_info.MaxSupply - NFT?.on_chain_info.NumSold;

  async function handleBuy() {
    const client = new Marketplace();
    client
      .initialize({
        network: __PROD__ ? Network.polygon : Network.mumbai,
        provider: provider
      })
      .then(async () => {
        if (paymentProcessing) return;

        if (active) {
          function transactionCallBack() {
            setClose();
            dispatch(
              setNotification({
                title: "Purchase Processing",
                type: NOTIF_PROGRESSING,
                description: purchase.processing
              })
            );
          }

          try {
            dispatch(setPaymentProcess(true));

            const transaction = isPublicSale
              ? await client.buy(NFT.contract_address, NFT.model_id, quantity, (status) => {
                  return status && transactionCallBack();
                })
              : await client.preorder(NFT.contract_address, NFT.model_id, quantity, (status) => {
                  return status && transactionCallBack();
                });
            if (transaction) {
              dispatch(addNFTNumSold(quantity)); // To update the NFT total sold for UI update
              dispatch(
                setNotification({
                  title: "Purchase Successful",
                  type: NOTIF_SUCCESS,
                  description: purchase.successful(NFT.name)
                })
              );
            }
          } catch (error) {
            dispatch(
              setNotification({
                title: "Purchase Failed",
                type: NOTIF_FAIL,
                description: purchase[error] || purchase["failed"]
              })
            );
            console.error(error);
            dispatch(setPaymentProcess(false));
          }
        } else {
          dispatch(
            setNotification({
              title: "Purchase Failed",
              type: NOTIF_FAIL,
              description: purchase.failed_wallet
            })
          );
        }
        dispatch(setPaymentProcess(false));
      });
  }

  return (
    <CustomDialog PaperProps={{ sx: { width: "373px" } }} setClose={setClose} open={isOpen}>
      <Typography component="h1" sx={{ p: 1, py: 1.5, fontSize: "28px", fontWeight: "bold" }}>
        Check Out
      </Typography>

      <StyledDivider />

      <Box py={1.5} display="flex" flexWrap="wrap" alignItems="center">
        <Typography sx={{ width: "100%" }}>Price</Typography>
        <Typography
          color="textPrimary"
          sx={{
            marginBottom: "5px",
            fontSize: "19px",
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}>
          <USDCLogo style={{ marginRight: "0.5rem" }} width={17} height={17} />{" "}
          {getUSDCPrice(NFT?.on_chain_info.Price)} {CURRENCY}
        </Typography>
      </Box>

      <StyledDivider />

      <Box py={1.5} display="flex" flexWrap="wrap" alignItems="center">
        <Typography sx={{ width: "100%" }}>Quantity</Typography>
        <NumberTextField
          maxValue={MAX_PURCHASE_ALLOWED > availableSupply ? availableSupply : MAX_PURCHASE_ALLOWED}
          value={quantity}
          setValue={setQuantity}
        />
        <Typography color="textSecondary" variant="caption">
          Available Supply {availableSupply}
        </Typography>
      </Box>

      <StyledDivider />

      <Box py={1.5} display="flex" flexWrap="wrap" alignItems="center">
        <Typography sx={{ width: "100%" }}>Total</Typography>
        <Typography
          sx={{
            marginBottom: "5px",
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
          color="primary"
          fontSize={14}
          fontWeight="bold"
          component="p">
          <USDCBlueLogo style={{ marginRight: "0.5rem" }} width={19} height={19} />{" "}
          {getUSDCPrice(NFT?.on_chain_info.Price) * quantity} {CURRENCY}
        </Typography>
      </Box>

      <Button
        disableRipple
        data-augmented-ui="tl-clip br-clip"
        variant="contained"
        onClick={handleBuy}
        startIcon={<LoadingIconView isLoading={paymentProcessing} />}
        sx={{
          width: "100%",
          height: "51px",
          mt: { xs: 0.5, sm: 4 },
          textTransform: "uppercase"
        }}>
        {paymentProcessing ? "processing..." : "buy now"}
      </Button>
    </CustomDialog>
  );
}

export default CheckoutDialog;
