import { useEffect, useState } from "react";
import spacetime from "spacetime";
import { DAY_MILLISECONDS, TIME_ZONE, decomposeTimeLeft } from "../utils/time";

export const SALE_STAGE = {
  UNDEFINED: 0,
  WAITING_PRE_SALE: 1,
  PRE_SALE: 2,
  WAITING_PUBLIC_SALE: 3,
  PUBLIC_SALE: 4,
  OPENSEA_SALE: 5
};

export const SALE_MODE = {
  BOTH: 0,
  PRE_SALE_ONLY: 1,
  PUBLIC_SALE_ONLY: 2,
  DISABLED: 3
};

const dateTimeFormat = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  day: "numeric"
});

export const useSaleCases = ({
  preSaleStartDate = Date.now(), // '2022-09-10 00:00'
  preSaleEndDate = Date.now() + DAY_MILLISECONDS, // '2022-09-16 00:00',
  publicSaleStartDate = Date.now() + DAY_MILLISECONDS * 2,
  publicSaleEndDate = Date.now() + DAY_MILLISECONDS * 4
} = {}) => {
  const [timeLeft, setTimeLeft] = useState(decomposeTimeLeft(0));
  const [preSaleDateRange, setPreSaleDateRange] = useState("");
  const [publicSaleDateRange, setPublicSaleDateRange] = useState("");
  const [currentSaleStage, setCurrentSaleStage] = useState(SALE_STAGE.UNDEFINED);
  const [currentSaleMode, setCurrentSaleMode] = useState(SALE_MODE.BOTH);
  const preSaleStartSpacetime = spacetime(preSaleStartDate, TIME_ZONE);
  const preSaleEndSpacetime = spacetime(preSaleEndDate, TIME_ZONE);
  const publicSaleStartSpacetime = spacetime(publicSaleStartDate, TIME_ZONE);
  const publicSaleEndSpacetime = spacetime(publicSaleEndDate, TIME_ZONE);

  const calculateSaleDateRanges = () => {
    try {
      // Warning: The method is not supported in IE
      setPreSaleDateRange(
        dateTimeFormat.formatRange(
          preSaleStartSpacetime.toNativeDate(),
          preSaleEndSpacetime.toNativeDate()
        )
      );
      setPublicSaleDateRange(
        dateTimeFormat.formatRange(
          publicSaleStartSpacetime.toNativeDate(),
          publicSaleEndSpacetime.toNativeDate()
        )
      );
    } catch (error) {
      // Try to run fallback
    }
  };

  const calculateSaleMode = () => {
    const $noPublicSale = publicSaleStartDate === 0 && publicSaleEndDate === 0;
    const $noPreSale = preSaleStartDate === 0 && preSaleEndDate === 0;

    let mode = SALE_MODE.BOTH;

    if ($noPublicSale && $noPreSale) {
      mode = SALE_MODE.DISABLED;
    } else if ($noPublicSale) {
      mode = SALE_MODE.PRE_SALE_ONLY;
    } else if ($noPreSale) {
      mode = SALE_MODE.PUBLIC_SALE_ONLY;
    }

    setCurrentSaleMode(mode);
  };

  const calculateSaleStage = () => {
    if (currentSaleMode === SALE_MODE.DISABLED) {
      return;
    }

    const nowSpacetime = spacetime(Date.now(), TIME_ZONE);
    let diff = nowSpacetime.diff(preSaleStartSpacetime);

    const isWaitingPublicSaleConditions = [
      () => currentSaleMode === SALE_MODE.BOTH && nowSpacetime.isAfter(preSaleEndSpacetime),
      () => currentSaleMode !== SALE_MODE.BOTH && nowSpacetime.isBefore(publicSaleStartSpacetime)
    ];

    if (nowSpacetime.isAfter(publicSaleEndSpacetime)) {
      setCurrentSaleStage(SALE_STAGE.OPENSEA_SALE);
    } else if (nowSpacetime.isAfter(publicSaleStartSpacetime)) {
      diff = nowSpacetime.diff(publicSaleEndSpacetime);
      setCurrentSaleStage(SALE_STAGE.PUBLIC_SALE);
    } else if (isWaitingPublicSaleConditions.some((fn) => fn())) {
      diff = nowSpacetime.diff(publicSaleStartSpacetime);
      setCurrentSaleStage(SALE_STAGE.WAITING_PUBLIC_SALE);
    } else if (nowSpacetime.isAfter(preSaleStartSpacetime)) {
      diff = nowSpacetime.diff(preSaleEndSpacetime);
      setCurrentSaleStage(SALE_STAGE.PRE_SALE);
    } else if (nowSpacetime.isBefore(preSaleStartSpacetime)) {
      diff = nowSpacetime.diff(preSaleStartSpacetime);
      setCurrentSaleStage(SALE_STAGE.WAITING_PRE_SALE);
    } else {
      // @Hack: Force set dirty date to SALE_STAGE.UNDEFINED
      setCurrentSaleStage(SALE_STAGE.UNDEFINED);
    }

    setTimeLeft(decomposeTimeLeft(diff.milliseconds));
  };

  useEffect(() => {
    calculateSaleMode();
    calculateSaleStage();
    calculateSaleDateRanges();

    const interval = setInterval(() => {
      calculateSaleStage();
    }, 1000);

    return () => clearInterval(interval);
  }, [preSaleStartDate, preSaleEndDate, publicSaleEndDate, publicSaleStartDate, currentSaleMode]);

  return {
    // isOnWhitelist,
    timeLeft,
    currentSaleStage,
    currentSaleMode,
    preSaleDateRange,
    publicSaleDateRange
  };
};
