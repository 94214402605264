import { Box, Typography } from "@mui/material";
import { PATH_EXTERNAL, PATH_INVENTORY } from "../routes/paths";

const notificationMessages = {
  purchase: {
    processing:
      "We are processing your purchase and you will receive a message once it is successful. You can check the status in your wallet. (10s Close)",
    processing_approval:
      "Please wait while your approval is being processed. This may take a couple of seconds.",
    dmaSuccessful: () => (
      <Typography>
        Your transaction has been submitted. It may take up to 40min to verify the on-chain
        transaction. You can check your DMA later on{" "}
        <Box component="a" href={PATH_EXTERNAL.dashboard.dma}>
          DMA Dashboard
        </Box>
        .
      </Typography>
    ),
    dmaAgiSuccessful: () => (
      <Typography>
        You successfully purchased. You can check your DMA on{" "}
        <Box component="a" href={PATH_EXTERNAL.dashboard.dma}>
          DMA Dashboard
        </Box>
        .
      </Typography>
    ),
    successful: (title, to = PATH_INVENTORY.root, linkTitle = "Inventory") => (
      <Typography>
        You successfully purchased {title}, check it in your{" "}
        <Box component="a" href={to} target="_blank">
          {linkTitle}
        </Box>
        .
      </Typography>
    ),

    client_token_not_equal: "The price has expired. Please attempt your transaction again.",

    failed: "Your purchase has been failed, please try again later.",
    failed_network:
      "Your wallet is connected to another network. Please change your network to Matic Mainnet.",
    Token_NoSupply: "Currently there is not enough supply for this NFT. Good luck next time!",
    PreOrder_NoSupply:
      "You have reached your limit for preordering this NFT. Please wait for the public sale.",
    Wallet_InsufficientAllowance:
      "Allowance is lower than the selected NFT's price. Please modify the allowance to Delysium's contract.",
    InsufficientBalance: 'Your current balance is insufficient. Please make a deposit.',
    Wallet_InsufficientBalance:
      "Your balance is not sufficient for this transaction. Please consider adding more tokens to your wallet.",
    failed_wallet: "Please connect your wallet first and set network as polygon mainnet."
  }
};

export default notificationMessages;
