import { configureStore } from "@reduxjs/toolkit";
import NFTReducer from "./slices/NFT";
import DMAReducer from "./slices/DMA";
import walletReducer from "./slices/wallet";
import settingsReducer from "./slices/settings";
import notificationsReducer from "./slices/notifications";
import authReducer from "./slices/auth";

export const store = configureStore({
  reducer: {
    NFT: NFTReducer,
    DMA: DMAReducer,
    wallet: walletReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    auth: authReducer
  }
});
