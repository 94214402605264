/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NFTCard from "../../components/NFTCard";
import { fullscreenCenterStyle } from "../../utils/styles";
import goToMarketplaceBackground from "../../assets/go-to-marketplace.png";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import {
  emptyNFTs,
  filterNFTs,
  getMoreNFTs,
  getMoreOwnedNFTs,
  getNFTs,
  getOwnedNFTs,
  resetPagination
} from "../../redux/slices/NFT";
import { PATH_MARKETPLACE } from "../../routes/paths";
import { PropTypes } from "prop-types";
import { useHistory, useLocation } from "react-router";
import { paramsToObject, removeEmptyParams } from "../../utils/strings";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import LOADING_IMAGE_URL from "../../assets/icons/processing-icon-transparent.gif";
import useBreakpoints from "../../hooks/useBreakpoints";
import { Helmet } from "react-helmet";
import Copyright from "../../components/Copyright";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";

// ----------------------------------------------------------------------
NFTList.propTypes = {
  isInventory: PropTypes.bool.isRequired
};
// ----------------------------------------------------------------------

const LoadingIconView = () => {
  const styles = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "30px",
    height: "30px",
    backgroundImage: `url(${LOADING_IMAGE_URL})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    mixBlendMode: "difference",
    margin: "0 auto"
  };

  return <Box sx={styles} />;
};

const InventoryEmtpyState = () => {
  const downMd = useBreakpoints("down", "md");
  const buttonStyle = {
    mt: 5,
    border: "none",
    fontWeight: "regular",
    backgroundImage: `url(${goToMarketplaceBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    padding: "19px 16px",
    color: (theme) => theme.palette.primary.main,

    "&::before": {
      display: "none"
    },
    "&::after": {
      display: "none"
    },
    "&:hover": {
      border: "none",
      textShadow: (theme) => `0px 0px 8px ${theme.palette.primary.main}`
    }
  };

  return (
    <>
      <Container sx={{ ...fullscreenCenterStyle(downMd ? "120px" : "320px") }}>
        No Items
        <Button
          disableRipple
          component={Link}
          to={PATH_MARKETPLACE.root}
          variant="outlined"
          color="primary"
          sx={buttonStyle}>
          GO TO MARKETPLACE
        </Button>
      </Container>
      <Copyright sx={{ display: { xs: "initial", md: "none" }, textAlign: "center" }} />
    </>
  );
};

const EmptyState = ({
  isEmpty,
  title = "There are currently no NFTs available in the marketplace"
}) => {
  const downMd = useBreakpoints("down", "md");

  if (!isEmpty) return null;

  return (
    <>
      <Box
        sx={{
          ...fullscreenCenterStyle(downMd ? "120px" : "320px"),
          textAlign: "center",
          width: "100%",
          flexGrow: 1
        }}>
        {title}
      </Box>
      <Copyright sx={{ display: { xs: "initial", md: "none" }, textAlign: "center" }} />
    </>
  );
};

// ----------------------------------------------------------------------

function NFTList({ isInventory }) {
  const dispatch = useDispatch();
  const [firstRender, setFirstRender] = useState(true);
  const { active, account } = useWeb3React();
  const { NFTs, isLoading, searchedNFT, filters, pagination, hasError } = useSelector(
    (state) => state.NFT
  );
  const isWrongNetwork = useIsWrongNetwork();
  const [searchedNFTs, setSearchedNFTs] = useState([]);
  const downMd = useBreakpoints("down", "md");
  const LoadingNFTs = [...Array(10)].fill({});
  const history = useHistory();
  const location = useLocation();

  useBottomScrollListener(
    () => {
      if (pagination.hasMore && !isLoading && !pagination.isLoadingMore) {
        var params = { ...filters, page: pagination.page + 1, page_size: pagination.page_size };
        if (params?.rarity === "all") params.rarity = "";
        params = removeEmptyParams(params);
        if (isInventory) dispatch(getMoreOwnedNFTs({ walletAddress: account, filters: params }));
        else if (!isInventory) dispatch(getMoreNFTs(params));
      }
    },
    { offset: 600 }
  );

  function handleGetNFTs(params) {
    if (isInventory && active) dispatch(getOwnedNFTs({ walletAddress: account, filters: params }));
    else if (isInventory && !active) dispatch(emptyNFTs());
    else if (!isInventory) dispatch(getNFTs(params));
  }

  useEffect(() => {
    //Called when there is an initial load (checks if there are parameters on URL to do the filtering based on that)
    const objectifiedParams = paramsToObject(location.search);
    dispatch(filterNFTs(objectifiedParams));
    
    if (window.location.pathname === '/') {
      history.replace('/dma')
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    //@FIXME initial call to server is done twice because of the following code
    if (!firstRender) {
      var params = { ...filters };
      if (params?.rarity === "all") params.rarity = "";
      params = removeEmptyParams(params);
      const stringifiedParams = new URLSearchParams(params).toString();
      history.push({ search: stringifiedParams });
      handleGetNFTs(params);
      dispatch(resetPagination());
    }
    setFirstRender(false);
  }, [filters, active, dispatch]);

  useEffect(() => {
    setSearchedNFTs(
      () =>
        NFTs?.filter(
          (NFT) =>
            NFT?.name?.toLowerCase().includes(searchedNFT?.toLowerCase()) ||
            searchedNFT?.length === 0
        ) || []
    );
  }, [searchedNFT, NFTs]);

  if (!NFTs?.length && !isLoading && !isInventory && !hasError) return <EmptyState isEmpty />;
  else if (!NFTs?.length && !isLoading && isInventory && !hasError) return <InventoryEmtpyState />;
  else if (!searchedNFTs.length && !isLoading && !hasError)
    return <EmptyState title="The NFT you are searching for cannot be found" isEmpty />;
  else if (hasError && !isLoading)
    return <EmptyState title="Server is under maintenance, please try again later." isEmpty />;

  // (If Searched OR filter exist, render only if NFT name is the same as searched and rarity is same as the filter set)
  return (
    <>
      {isInventory && (
        <Helmet>
          <title>Inventory - Delysium Marketplace</title>
        </Helmet>
      )}
      <Grid
        container
        sx={{
          display: "grid",
          ml: { xs: 0, md: 1 },
          mt: { xs: isWrongNetwork ? 0 : 10, md: 1 },
          gridTemplateColumns: {
            xs: "repeat(auto-fit, minmax(300px, 1fr))",
            //on large screens, nft cards (if less than 3) can expand to a point where it looks not too good!
            //Therefore this condition is added to prevent over-expanding
            lg: `repeat(auto-fit, minmax(300px, ${
              searchedNFTs.length < 4 && !isLoading ? "330px" : "1fr"
            }))`
          }
        }}>
        {(isLoading ? LoadingNFTs : searchedNFTs).map((NFT, index) => {
          const mt = NFT?.amount > 2 ? 3.25 : NFT?.amount === 2 ? 2.5 : 2.5;
          return (
            <Grid sx={{ mt, mb: 2, mx: { xs: 1, md: 2 } }} display="flex" key={index}>
              <NFTCard NFT={NFT} isOwned={isInventory} isSkeleton={isLoading} />
            </Grid>
          );
        })}
      </Grid>

      {pagination.isLoadingMore && <LoadingIconView />}

      {downMd && (
        <Copyright
          sx={{ textAlign: "center" }}
          textProps={{ sx: { display: "initial", margin: "0 auto" } }}
        />
      )}
    </>
  );
}

export default NFTList;
