import React, { useEffect, useState } from "react";
import { ReactComponent as FailedIcon } from "../assets/icons/notif-fail.svg";
import { ReactComponent as SuccessIcon } from "../assets/icons/notif-success.svg";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import BackgroundEffect from "../assets/bg-effect.png";
import CloseButton from "./CloseButton";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInRight } from "./Animate";
import {
  setClose as setNotificationClose,
  NOTIF_SUCCESS,
  NOTIF_PROGRESSING,
  NOTIF_FAIL
} from "../redux/slices/notifications";
import LOADING_IMAGE_URL from "../assets/icons/processing-icon-transparent.gif";
import { makeStyles } from "@mui/styles";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      "--aug-border-all": "0.5px",
      "--aug-border-bg": theme.palette.primary.main,
      "--aug-bl": "6px",
      "--aug-bl-inset1": "calc(var(--aug-bl1) * 7)",
      "--aug-b-extend1": "50%"
    }
  };
});

// ----------------------------------------------------------------------

const LoadingIconView = () => {
  const styles = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "18px",
    height: "18px",
    backgroundImage: `url(${LOADING_IMAGE_URL})`,
    backgroundSize: "contain",
    mixBlendMode: "difference",
    marginRight: "7px"
  };

  return <Box sx={styles} />;
};

function StatusIcon({ type, ...props }) {
  if (type === NOTIF_SUCCESS) return <SuccessIcon {...props} />;
  else if (type === NOTIF_PROGRESSING) return <LoadingIconView {...props} />;
  return <FailedIcon {...props} />;
}

// ----------------------------------------------------------------------

const NotificationBar = ({ ...props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [color, setColor] = useState("primary");
  const { type, title, description, isOpen } = useSelector((state) => state.notifications);

  useEffect(() => {
    if (type === NOTIF_FAIL) setColor("secondary");
    else if (type === NOTIF_SUCCESS || type === NOTIF_PROGRESSING) setColor("primary");
  }, [type]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Box
          className={classes.paper}
          style={{
            // Position gets overridden because of Augmented UI
            position: "fixed"
          }}
          data-augmented-ui="bl-clip-y border"
          component={motion.div}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 10,
            position: "fixed",
            top: "5rem",
            right: "1.5rem",
            width: "292px",
            padding: "15px 28px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.primary.main,
            backgroundImage: `url(${BackgroundEffect})`
          }}
          {...varFadeInRight}
          {...props}>
          <CloseButton onClick={() => dispatch(setNotificationClose())} />
          <Box display="flex" flexDirection="column" flexWrap="nowrap">
            <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
              <StatusIcon
                type={type}
                style={{ width: "18px", height: "18px", marginRight: "0.5rem" }}
              />
              <Typography component="h6" variant="h6" color={color}>
                {title}
              </Typography>
            </Box>

            <Typography component="p" variant="caption">
              {typeof description === "object" ? description.props.children : description}
            </Typography>
          </Box>
        </Box>
      )}
    </AnimatePresence>
  );
};

export default NotificationBar;
