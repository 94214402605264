import { alpha } from "@mui/material";
import { withStyles } from "@mui/styles";

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  "@global": {
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "border-box"
    },

    html: {
      width: "100%",
      height: "100%",
      "-ms-text-size-adjust": "100%",
      "-webkit-overflow-scrolling": "touch"
    },
    body: {
      width: "100%",
      height: "100%",
      overflowX: "hidden",

      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        width: 8,
        height: 6,
        backgroundColor: theme.palette.divider
      },
      "&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb": {
        border: "none",
        borderRadius: 8,
        backgroundColor: alpha(theme.palette.grey[600], 0.48)
      }
    },

    ".MuiButton-root": {
      border: "none",
      "--aug-border-all": "1px",
      "--aug-br": "7px",
      "--aug-tl": "7px",
      "&.MuiButton-outlined.MuiButton-outlinedPrimary": {
        border: "none",
        "--aug-border-bg": theme.palette.primary.lighter
      },
      "&.MuiButton-outlined.MuiButton-outlinedSecondary": {
        border: "none",
        "--aug-border-bg": theme.palette.secondary.lighter
      },
      "&.MuiButton-outlined.MuiButton-outlinedPrimary:hover": {
        border: "none",
        "--aug-border-bg": theme.palette.primary.main
      }
    },

    ".MuiOutlinedInput-root": {
      border: "none",
      "--aug-border-all": "1.5px",
      "--aug-border-bg": theme.palette.grey[700],
      "--aug-br": "10px",
      "--aug-tl": "10px",

      "& > fieldset": { border: "none" },

      "&.MuiInputBase-colorPrimary, &.MuiInputBase-colorPrimary.Mui-focused, &.MuiInputBase-colorPrimary:hover":
        {
          "--aug-border-bg": theme.palette.primary.main
        },

      "&.MuiInputBase-colorSecondary.Mui-focused, &.hasInput, &:hover": {
        "--aug-border-bg": theme.palette.text.primary,
        borderColor: "initial",
        border: "none"
      }
    },

    input: {
      "&[type=number]": {
        MozAppearance: "textfield",
        "&::-webkit-outer-spin-button": { margin: 0, WebkitAppearance: "none" },
        "&::-webkit-inner-spin-button": { margin: 0, WebkitAppearance: "none" }
      }
    },

    a: {
      color: theme.palette.primary.main
    },

    img: {
      display: "block",
      maxWidth: "100%"
    },
    "@keyframes growIn": {
      "0%": {
        opacity: 0,
        height: 0
      },
      "100%": {
        opacity: 1,
        height: "100%"
      }
    },
    "@keyframes growOut": {
      "0%": {
        opacity: 1,
        height: "100%"
      },
      "100%": {
        opacity: 0,
        height: 0
      }
    }
  }
}))(() => null);

export default GlobalStyles;
