import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------

GradientBackground.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
// ----------------------------------------------------------------------

function GradientBackground({ height, ...props }) {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: (theme) => theme.zIndex.appBar - 1,
        width: "100vw",
        height: height,
        background: "linear-gradient(180deg, #000 0%, #000 84.4%, rgba(7, 9, 17, 0) 100%)",
        ...props?.sx
      }}
    />
  );
}

export default GradientBackground;
