import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { NOTIF_FAIL, setNotification } from "../../../redux/slices/notifications";
import { setLocalStorage } from "../../../utils/localStorage";
import PropTypes from "prop-types";
import { COINBASE, METAMASK, WALLETCONNECT } from "../../../utils/wallet";
import { getConnectorName } from "../../../utils/connectors";
import ReactGA from "react-ga4";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { isMobile } from "../../../utils/env";
// ----------------------------------------------------------------------

WalletLogin.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.string.isRequired,
  connector: PropTypes.object.isRequired
};
// ----------------------------------------------------------------------
function WalletLogin({ closeDialog, title, Icon, connector }) {
  const dispatch = useDispatch();
  const { activate, active } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Box component="img" alt={title} src={Icon} width={19} height={19} />
        <Typography sx={{ ml: 2, textTransform: "capitalize" }}>{title}</Typography>
      </Box>
      <Button
        disableRipple
        data-augmented-ui="tl-clip br-clip border"
        variant="outlined"
        color="primary"
        onClick={async () => {
          if (isLoading && !active) return;
          try {
            setIsLoading(true);
            if (window.ethereum || title === WALLETCONNECT) {
              await activate(connector);
              setLocalStorage("connector", getConnectorName(connector));
              ReactGA.event(`enter_${title}_login`);
              closeDialog();
            } else if (isMobile() && !window.ethereum) {
              const { host, pathname } = window.location;
              const pathToWalletApp = `${host}/${pathname}`;

              if (title === COINBASE) {
                window.open(`https://go.cb-w.com/dapp?cb_url=${pathToWalletApp}`);
              } else if (title === METAMASK) {
                window.open(`https://metamask.app.link/dapp/${pathToWalletApp}`);
              }
            }
            setIsLoading(false);
          } catch (error) {
            dispatch(
              setNotification({
                type: NOTIF_FAIL,
                title: "Login Failed",
                description: error.message
              })
            );
            setIsLoading(false);
          }
        }}
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          justifyContent: "center",
          height: "20px",
          minWidth: "77px",
          fontWeight: "normal",
          backgroundImage: "none",
          color: "text.secondary",
          fontSize: "12px"
        }}>
        {isLoading ? <LoadingSpinner size="8" /> : "Connect"}
      </Button>
    </Box>
  );
}

export default WalletLogin;
