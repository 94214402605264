import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { ReactComponent as USDCLogo } from "../../assets/icons/usdc_white.svg";
import { ReactComponent as Border } from "../../assets/nft-card-border.svg";
import { ReactComponent as BorderHover } from "../../assets/nft-card-border-hover.svg";
import { ReactComponent as DoubleBorder } from "../../assets/nft-card-border-two-layers.svg";
import { ReactComponent as DoubleBorderHover } from "../../assets/nft-card-border-two-layers-hover.svg";
import { ReactComponent as Owned } from "../../assets/icons/owned_icon.svg";
import CustomSkeleton from "../Skeleton";
import { CURRENCY, getRarityObject, getUSDCPrice } from "../../utils/NFTs";
import delysiumLoadingIcon from "../../assets/icons/delysium-loading-icon.svg";
import { PATH_INVENTORY, PATH_NFT } from "../../routes/paths";
import PropTypes from "prop-types";
import CustomTooltip from "../Tooltip";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

NFTCard.propTypes = {
  NFT: PropTypes.object.isRequired,
  isOwned: PropTypes.bool,
  isSkeleton: PropTypes.bool
};
// ----------------------------------------------------------------------

const commonBorderStyles = {
  position: "absolute",
  left: -10,
  zIndex: -1
};

const borderStyles = {
  width: "100%",
  top: "-0.6rem",
  opacity: 0.5,
  ...commonBorderStyles
};

const doubleBorderStyles = {
  width: "100%",
  top: "-0.84rem",
  ...commonBorderStyles
};

const NFTCardSX = {
  position: "relative",
  overflow: "initial",
  width: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",

  border: (theme) => `1px solid ${theme.palette.grey[700]}`,
  backgroundColor: "transparent",
  backgroundImage: "initial",
  borderRadius: 0,
  textDecoration: "none",

  transition: (theme) =>
    theme.transitions.create("border", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),

  "&::after": {
    content: "''",
    position: "absolute",
    top: "-1px",
    right: "7px",
    zIndex: 1,
    width: "2.9rem",
    height: "1rem",
    transform: "skewX(44deg)",
    background: (theme) => theme.palette.background.default,
    borderBottom: (theme) => `1px solid ${theme.palette.grey[700]}`,
    borderLeft: (theme) => `2px solid ${theme.palette.grey[700]}`,
    transition: "inherit"
  },

  "&::before": {
    content: "''",
    position: "absolute",
    top: "-2px",
    right: "-2px",
    zIndex: 2,
    width: "2rem",
    height: "16px",
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 21% 100%)",
    background: (theme) => theme.palette.background.default
  },

  "&:hover": {
    borderColor: (theme) => theme.palette.primary.main,

    "&::after": {
      borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
      borderLeft: (theme) => `2px solid ${theme.palette.primary.main}`
    }
  }
};

const SkeletonView = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ width: "100%", minHeight: "398px" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", flexGrow: 1 }}>
        <Box maxWidth="54px" height="auto" component="img" src={delysiumLoadingIcon} />
      </Box>
      <Box sx={{ p: 2 }}>
        <CustomSkeleton />
        <CustomSkeleton sx={{ marginTop: "20px" }} width="28%" />
      </Box>
    </Box>
  );
};

const ContentView = ({ NFT, isOwned }) => {
  const { Icon } = getRarityObject(NFT?.rarity);
  const stockQuantity = NFT?.on_chain_info?.MaxSupply - NFT?.on_chain_info?.NumSold;
  const shouldShowTotalOwned = isOwned && NFT?.category.toLowerCase() !== "weapon";
  const shouldShowPrice = !isOwned && NFT?.product_type?.toLowerCase() !== "badge";

  return (
    <>
      <CardMedia
        sx={{ objectFit: "contain", minHeight: "330px" }}
        component="img"
        image={NFT?.metadata?.image ? NFT?.metadata?.image : delysiumLoadingIcon}
        alt={NFT?.metadata?.name}
      />

      <CardContent sx={{ p: 2, "&:last-child": { pb: 1 } }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}>
          <CustomTooltip title={NFT?.metadata?.name}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}
              fontWeight="bold"
              variant="body2">
              {NFT?.metadata?.name}
            </Typography>
          </CustomTooltip>

          <Typography sx={{ lineHeight: 1 }} component="span">
            <Icon />
          </Typography>
        </Box>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end"
          }}
          variant="body2"
          color="text.secondary"
          component="div">
          {shouldShowTotalOwned && (
            <Typography
              display="flex"
              alignItems="center"
              variant="caption"
              textTransform="uppercase"
              sx={{
                color: (theme) => alpha(theme.palette.text.secondary, 0.7)
              }}>
              <Owned style={{ marginRight: "0.5rem" }} />
              <Typography variant="caption" mr={0.5} color="primary">
                {NFT.amount}
              </Typography>
              Owned
            </Typography>
          )}

          {shouldShowPrice && stockQuantity > 0 && (
            <Typography
              variant="body2"
              fontWeight={400}
              textTransform="upperCase"
              sx={{ display: "flex", alignItems: "center" }}>
              <USDCLogo style={{ marginRight: "0.25rem", width: 14, height: 14 }} />
              {getUSDCPrice(NFT?.on_chain_info?.Price)} {CURRENCY}
            </Typography>
          )}
          {shouldShowPrice && stockQuantity <= 0 && (
            <Typography sx={{ opacity: 0.7 }} variant="body2" color="primary">
              Sold Out
            </Typography>
          )}
        </Typography>
      </CardContent>
    </>
  );
};

// const useStyles = makeStyles((theme) => {
//   return {
//     nftCard: {
//       "--aug-border-all": "0.2px",
//       "--aug-border-bg": theme.palette.grey[700],
//       "--aug-tr1": "18px",
//       "--aug-tr-inset2": "calc(var(--aug-tr1) * 2.5)",
//       "&:hover": {
//         "--aug-border-all": "1px",
//         "--aug-border-left": "1.5px",
//         "--aug-border-bg": theme.palette.primary.main
//       }
//     }
//   };
// });

function NFTCard({ NFT, isOwned = false, isSkeleton = false, ...props }) {
  // const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const pathToNFT = isOwned
    ? `${PATH_INVENTORY.root}/${NFT.model_id || NFT.token_id}`
    : `${PATH_NFT.root}/${NFT.model_id}`;

  return (
    <Card
      sx={NFTCardSX}
      // data-augmented-ui="tr-clip-x border"
      // className={classes.nftCard}
      component={isSkeleton ? "div" : Link}
      // @FIXME: This is a temp method to avoid view cache
      //         Need to replace with a React way
      to={pathToNFT}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}>
      {isOwned &&
        NFT.amount === 2 &&
        (isHovered ? <BorderHover style={borderStyles} /> : <Border style={borderStyles} />)}
      {isOwned &&
        NFT.amount > 2 &&
        (isHovered ? (
          <DoubleBorderHover style={doubleBorderStyles} />
        ) : (
          <DoubleBorder style={doubleBorderStyles} />
        ))}

      {isSkeleton ? <SkeletonView /> : <ContentView NFT={NFT} isOwned={isOwned} />}
    </Card>
  );
}

export default NFTCard;
