import { createSlice } from "@reduxjs/toolkit";

export const NOTIF_SUCCESS = "success";
export const NOTIF_FAIL = "fail";
export const NOTIF_PROGRESSING = "progress";

const initialState = {
  isOpen: false,
  type: NOTIF_PROGRESSING,
  title: "Hey",
  description: "This is a notification"
};

export const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setOpen(state) {
      state.isOpen = true;
    },

    setClose(state) {
      state.isOpen = false;
    },

    setNotificationSuccess(state, action) {
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.description = action.payload.description;
    }
  },
  extraReducers: (builder) => {}
});

export function setNotification({ type, title, description, time = 10000 }) {
  return async (dispatch) => {
    // If it is related to user not logged in, do not need to show a notification
    if (description !== "Authentication token missing.") {
      dispatch(slice.actions.setNotificationSuccess({ type, title, description }));
      dispatch(slice.actions.setOpen());
      setTimeout(() => {
        dispatch(slice.actions.setClose());
      }, time);
    }
  };
}

export default slice.reducer;
export const { setClose } = slice.actions;
