import { Box } from "@mui/system";

export default function Skeleton({
  width = "100%",
  height = "6px",
  slashWidth = "6px",
  sx = {},
  ...rest
}) {
  const rootStyle = {
    width,
    height,
    ...sx,
    ...rest
  };

  const skeletonLineStyle = {
    position: "relative",
    color: "#4E4E4E",
    height: "100%",
    width: `calc(100% - ${slashWidth})`,
    marginLeft: slashWidth,
    borderTop: "0.5px solid currentColor",

    "&::before": {
      content: "''",
      position: "absolute",
      width: slashWidth,
      height: "100%",
      top: "0px",
      left: `calc(${slashWidth} * -1)`,
      background:
        "linear-gradient(to top left, transparent calc(50% - 0.5px), currentColor, transparent calc(50% + 0.5px) )"
    }
  };

  return (
    <Box sx={rootStyle}>
      <Box sx={skeletonLineStyle} />
    </Box>
  );
}
