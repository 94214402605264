import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { varFadeInUp } from "./Animate";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, DialogContent } from "@mui/material";
import CloseButtonComponent from "./CloseButton";
import { makeStyles } from "@mui/styles";

// ----------------------------------------------------------------------

CustomDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  PaperProps: PropTypes.object,
  CloseButton: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      "--aug-border-all": "1px",
      "--aug-border-left": "1.5px",
      "--aug-border-bg": theme.palette.primary.main,
      "--aug-bl": "14px",
      "--aug-bl-inset1": "calc(var(--aug-bl1) * 3)",
      "--aug-b-extend1": "50%"
    }
  };
});

function CustomDialog({
  children,
  width = "md",
  open,
  setClose,
  fullWidth = false,
  PaperProps = {},
  CloseButton = ({ ...props }) => <CloseButtonComponent {...props} />,
  ...props
}) {
  const classes = useStyles();

  return (
    <AnimatePresence>
      <Dialog
        open={open}
        maxWidth={width}
        onClose={setClose}
        fullWidth={fullWidth}
        PaperComponent={motion.div}
        {...props}
        PaperProps={{
          ...varFadeInUp,
          "data-augmented-ui": "bl-clip-y br-clip border",
          ...PaperProps,
          className: clsx(classes.paper, PaperProps.className),
          sx: {
            ...PaperProps?.sx
          }
        }}>
        <DialogContent>
          {children}
          <CloseButton onClick={setClose} />
        </DialogContent>
      </Dialog>
    </AnimatePresence>
  );
}

export default CustomDialog;
