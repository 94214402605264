import { config } from "../config";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  marketplace: "/",
  inventory: "/inventory",
  ownedNFT: "/inventory/:modelId",
  NFT: "/nft",
  DMA: "/dma",
  account: "/account",
  external: {
    dashboard: config.DASHBOARD_CLIENT_URL,
    website: config.WEB_CLIENT_URL,
    sso: config.SSO_CLIENT_URL
  }
};

// Paths and routes external to marketplace
const SSO_REDIRECT_PARAMETER = `redirect_url=${window.location.href}`;

export const PATH_EXTERNAL = {
  sso: {
    root: ROOTS.external.sso,
    login: path(ROOTS.external.sso, `/login?${SSO_REDIRECT_PARAMETER}`),
    register: path(ROOTS.external.sso, `/register?${SSO_REDIRECT_PARAMETER}`)
  },
  dashboard: {
    settings: path(ROOTS.external.dashboard, "/settings"),
    dma: path(ROOTS.external.dashboard, "/dma")
  },
  website: {
    root: ROOTS.external.website,
    privacyPolicy: path(ROOTS.external.website, "/account/privacy-policy"),
    termsOfUse: path(ROOTS.external.website, "/account/terms-of-use"),
    about: path(ROOTS.external.website, "/about"),
    whitepaper: path(ROOTS.external.website, "/whitepaper")
  }
};

// Paths within marketplace env
export const PATH_DMA = {
  root: ROOTS.DMA
};

export const PATH_MARKETPLACE = {
  root: ROOTS.marketplace
};

export const PATH_ERROR = {
  NOT_FOUND: "*"
};

export const PATH_INVENTORY = {
  root: ROOTS.inventory
};

export const PATH_OWNED_NFT = {
  root: ROOTS.ownedNFT
};

export const PATH_NFT = {
  root: ROOTS.NFT,
  NFTView: path(ROOTS.NFT, "/:modelId"),
  suiteNFT: path(ROOTS.NFT, "/suit"),
  buy: path(ROOTS.NFT, "/:id/buy")
};
