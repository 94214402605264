import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomPopover from "../../components/Popover";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import copyTextToClipboard from "copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import { AnimatePresence, motion } from "framer-motion";
import { varZoomInOut } from "../../components/Animate";
import { setLocalStorage } from "../../utils/localStorage";
import PropTypes from "prop-types";
import { getConnectorName } from "../../utils/connectors";
import ReactGA from "react-ga4";

// ----------------------------------------------------------------------

WalletPopover.propTypes = {
  isWalletOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object
};

// ----------------------------------------------------------------------

function WalletPopover({ isWalletOpen, anchorElWal, handleClose }) {
  const { account, deactivate } = useWeb3React();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <CustomPopover height="114px" open={isWalletOpen} anchorEl={anchorElWal} onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <AnimatePresence>
          {copied && (
            <Typography
              variant="caption"
              textTransform="uppercase"
              component={motion.span}
              align="center"
              color="primary"
              sx={{
                width: "100%",
                position: "absolute",
                top: 3,
                left: 0,
                transform: "translate(0,-50%)"
              }}
              {...varZoomInOut}>
              copied
            </Typography>
          )}
        </AnimatePresence>
        <Button
          disableRipple
          variant="outlined"
          color="primary"
          data-augmented-ui="tl-clip br-clip border"
          onClick={() => {
            setCopied(true);
            copyTextToClipboard(account);
          }}
          sx={{
            color: (theme) => theme.palette.primary.main
          }}
          endIcon={<CopyIcon sx={{ cursor: "pointer" }} />}>
          {`${account?.substr(0, 6)}...${account?.substr(account?.length - 4, 4)}`}
        </Button>

        <Button
          disableRipple
          variant="outlined"
          color="secondary"
          data-augmented-ui="tl-clip br-clip border"
          onClick={() => {
            ReactGA.event("disconnect_wallet");
            setLocalStorage("connector", getConnectorName());
            deactivate();
            handleClose();
          }}>
          Disconnect
        </Button>
      </Box>
    </CustomPopover>
  );
}

export default WalletPopover;
