import React, { useState } from "react";
import { alpha, Box } from "@mui/system";
import { IconButton, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as AdderUp } from "../assets/adder-up.svg";
import { ReactComponent as AdderUpHover } from "../assets/adder-up-hover.svg";
import { ReactComponent as AdderDown } from "../assets/adder-down.svg";
import { ReactComponent as AdderDownHover } from "../assets/adder-down-hover.svg";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------

NumberTextField.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  containerProps: PropTypes.object
};
// ----------------------------------------------------------------------

function NumberTextField({
  value,
  setValue,
  maxValue = 999,
  minValue = 1,
  containerProps,
  ...props
}) {
  const useStyles = makeStyles((theme) => {
    const OFFSET = 7;

    return {
      iconsShared: {
        position: "absolute",
        right: 21,
        width: "40px",
        height: "12px",
        transition: "all 0.3s ease",
        padding: 0
      },

      adderUp: {
        top: OFFSET,
        cursor: value < maxValue && "pointer",
        opacity: value >= maxValue && 0.5
      },

      adderDown: {
        bottom: 9,
        cursor: value > minValue && "pointer",
        opacity: value <= minValue && 0.5
      }
    };
  });
  const classes = useStyles();
  const [upHover, setUpHover] = useState(false);
  const [downHover, setDownHover] = useState(false);

  function handleChangeQuantity(value) {
    if (value < 0) return setValue(0);
    if (value.length === 0) setValue("");
    else setValue(parseInt(value));
  }

  return (
    <Box width="100%" position="relative" my={0.5} {...containerProps}>
      <TextField
        sx={{ width: "100%" }}
        variant="outlined"
        color="secondary"
        type="number"
        value={value}
        onChange={(e) => {
          const { value } = e.target;
          handleChangeQuantity(value);
        }}
        InputProps={{
          inputProps: { max: maxValue, pattern: "[0-9]" },
          "data-augmented-ui": "tl-clip br-clip border",
          sx: {
            fontSize: "24px",
            height: "46px",
            "&.Mui-focused": {
              color: ({ palette }) => alpha(palette.text.primary, 0.8)
            },
            color: ({ palette }) => alpha(palette.text.primary, 0.8)
          }
        }}
        {...props}
      />
      <IconButton
        disableRipple
        onMouseEnter={() => setUpHover(true)}
        onMouseLeave={() => setUpHover(false)}
        onClick={() => handleChangeQuantity(value + 1)}
        className={`${classes.iconsShared} ${classes.adderUp}`}>
        {upHover ? <AdderUpHover /> : <AdderUp />}
      </IconButton>
      <IconButton
        disableRipple
        onMouseEnter={() => setDownHover(true)}
        onMouseLeave={() => setDownHover(false)}
        onClick={() => handleChangeQuantity(value - 1)}
        className={`${classes.iconsShared} ${classes.adderDown}`}>
        {downHover ? <AdderDownHover /> : <AdderDown />}
      </IconButton>
    </Box>
  );
}

export default NumberTextField;
