import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { makeStyles } from "@mui/styles";
import { ReactComponent as DownArrow } from "../assets/icons/down-arrow.svg";
import { ReactComponent as USDTLogo } from "../assets/icons/usdt.svg";
import { ReactComponent as RefreshIcon } from "../assets/icons/refresh.svg";
import { ReactComponent as USDTBlueLogo } from "../assets/icons/usdt-blue.svg";
import { ReactComponent as AGIBlueLogo } from "../assets/icons/agi-blue.svg";
import { ReactComponent as CheckboxIcon } from "../assets/checkbox-checked.svg";
import { ReactComponent as CheckboxIconEmpty } from "../assets/checkbox-empty.svg";
import { ReactComponent as Checkmark } from "../assets/icons/check-mark-green.svg";
import { Network, DMA as DMAClientSDK } from "@delysium/client";
import NumberTextField from "./NumberTextfield";
import CustomDialog from "./Dialog";
import { DMA_CURRENCY, getUSDCPrice } from "../utils/NFTs";
import { useWeb3React } from "@web3-react/core";
import {
  NOTIF_FAIL,
  NOTIF_PROGRESSING,
  NOTIF_SUCCESS,
  setNotification
} from "../redux/slices/notifications";
import {
  getDMA,
  getDMAPrice,
  buyDMAByAGI,
  buyDMASuccess,
  setDmaPrice,
  setReferralCode,
  setPaymentProcess,
  signMessage,
  validateReferralCode
} from "../redux/slices/DMA";
import notifications from "../constants/notifications";
import LOADING_IMAGE_URL from "../assets/icons/processing-icon.gif";
import { PropTypes } from "prop-types";
import { PATH_EXTERNAL } from "../routes/paths";
import LoadingSpinner from "./LoadingSpinner";
import useBreakpoints from "../hooks/useBreakpoints";
import CloseButton from "./CloseButton";
import { setWalletConnectionOpen } from "../redux/slices/wallet";
import { CHAINS, changeNetwork, DMA_CORRECT_CHAIN } from "../utils/chains";
import { __DEBUG__, __PROD__ } from "../utils/dev";
import useIsWrongNetwork from "../hooks/useIsWrongNetwork";
import { getLocalStorage } from "../utils/localStorage";
import { WALLETCONNECT } from "../utils/wallet";
import { useLocation } from "react-router";
import { withComma } from "../utils/strings";
import { motion } from "framer-motion";

// ----------------------------------------------------------------------
DMACheckoutDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isPublicSale: PropTypes.bool,
  isPreSale: PropTypes.bool
};
// ----------------------------------------------------------------------

const ButtonLoadingIcon = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  const styles = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "18px",
    height: "18px",
    backgroundImage: `url(${LOADING_IMAGE_URL})`,
    backgroundSize: "contain",
    mixBlendMode: "difference",
    marginRight: "7px"
  };

  return <Box sx={styles} />;
};

const MAX_PURCHASE_ALLOWED = 100;
const BYBIT_CODE = "BYBIT";

function ExternalLink({ to, title, ...props }) {
  return (
    <Typography
      component="a"
      variant="body2"
      target="_blank"
      fontSize="inherit"
      lineHeight="inherit"
      href={to}
      color="textSecondary"
      rel="noreferrer noopener"
      {...props}
      sx={{ textDecoration: "underlined", ...props?.sx }}>
      {title}
    </Typography>
  );
}

function CustomCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      icon={<CheckboxIconEmpty />}
      checkedIcon={<CheckboxIcon />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: "flex",
      flexFlow: "column nowrap",
      // height: "668px",
      width: "393px",
      position: "relative",
      padding: "0 12px",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        display: "none"
      },
      [theme.breakpoints.down("md")]: {
        width: "101%",
        height: "95vh",
        maxHeight: "610px",
        position: "absolute",
        left: -2,
        bottom: -2,
        background: "#181818",
        padding: 0,
        margin: 0,
        "--aug-tr1": "12px",
        "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
      }
    },
    buyButtonContainer: {
      width: "100%",
      marginTop: "8px",
      [theme.breakpoints.down("md")]: {
        padding: "16px 20px 12px 16px",
        position: "fixed",
        left: 0,
        bottom: 0,
        backgroundColor: "#181818"
      },

      "& button": {
        width: "100%",
        height: "43px",
        margin: "1rem auto",
        textTransform: "uppercase",

        [theme.breakpoints.down("md")]: {
          height: "38px",
          margin: "0"
        }
      }
    }
  };
});

const INCREASE_RATE = 25;
const BASE_PRICE = 1000;
function calculatePrice(totalSold, quantity) {
  let toBePayed = 0;
  // first 10000
  if (totalSold + quantity <= 10000) return BASE_PRICE * quantity;
  // before quantity under 10000 but after quantity above 10000
  if (totalSold < 10000) {
    toBePayed += (10000 - totalSold) * BASE_PRICE;
    quantity += totalSold - 10000;
    totalSold = 10000;
  }
  // current DMA price each
  let price = (Math.floor((totalSold - 10000) / 100) + 1) * INCREASE_RATE + BASE_PRICE;
  // how many DMA left at this price
  let left = 100 - (totalSold % 100);
  if (quantity <= left) return toBePayed + price * quantity;
  // quantity DMA at this price
  toBePayed += price * left;
  // remaining number to quantity
  quantity -= left;
  // remaining groups with number of 100
  let t = Math.floor(quantity / 100);
  // quantity DMA in groups
  toBePayed += (price * 2 + INCREASE_RATE * t + INCREASE_RATE) * t * 50;
  // quantity the last group of DMA
  toBePayed += (price + INCREASE_RATE * (t + 1)) * (quantity % 100);
  return toBePayed;
}

function DMACheckoutDialog({ isOpen, setClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const downMd = useBreakpoints("down", "md");
  const { purchase } = notifications;
  const isWrongNetwork = useIsWrongNetwork();
  const [quantity, setQuantity] = useState(1);
  const [bybitId, setBybitId] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [isBuyUsdt, setIsBuyUsdt] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedConditions, setCheckedConditions] = useState(false);
  const anchorRef = useRef(null);
  const {
    DMA,
    balance,
    paymentProcessing,
    isValidCode,
    validatingReferralCode,
    referralCode,
    dmaPrice,
    submittedReferralCode,
    isRefreshingPrice
  } = useSelector((state) => state.DMA);
  const { library: provider, active } = useWeb3React();
  const availableSupply = DMA?.on_chain_info.MaxSupply - DMA?.on_chain_info.NumSold;
  const isBuyButtonDisabled = [
    () => quantity.length === 0 || quantity === 0,
    () => !checkedTerms || !checkedConditions,
    () => isRefreshingPrice,
    () => !isValidCode && referralCode.length > 0,
    () => quantity > MAX_PURCHASE_ALLOWED
  ];
  const isReferralInputDisabled = [() => submittedReferralCode || validatingReferralCode];
  const nativeBalance = Number(
    balance.find((item) => item.currency === (isBuyUsdt ? 'USDT' : 'DES'))?.amount || 0
  );
  function handleValidateCode(code) {
    dispatch(validateReferralCode(code));
  }

  useEffect(() => {
    const params = new URLSearchParams(search);
    const code = params.get("referrer");
    dispatch(getDMAPrice());
    if (!submittedReferralCode && code) {
      dispatch(setReferralCode(code));
      handleValidateCode(code);
    }
  }, [submittedReferralCode, dispatch, search]);

  const prevOpen = useRef(openMenu);
  useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenMenu(false);
  };

  async function handleAGIBuy() {
    if (paymentProcessing) return;

    function transactionCallBack() {
      setClose();
      dispatch(
        setNotification({
          title: "Purchase Processing",
          type: NOTIF_PROGRESSING,
          description:
            "Your purchase is pending and will be reviewed shortly. You will be notified when the on-chain transaction is submitted. (5s close)"
        })
      );
    }

    try {
      dispatch(setPaymentProcess(true));
      const signResponse = await dispatch(
        buyDMAByAGI({
          amount: quantity,
          used_code: referralCode,
          client_token_to_pay: String(dmaPrice.agi_price_list[quantity -1])
        })
      ).then((response) => {
        if (response?.error) return false;
        else return response;
      });

      if (signResponse === false) return;

      const transaction = true
      transactionCallBack();

      if (transaction) {
        dispatch(buyDMASuccess(quantity));
        dispatch(
          setNotification({
            title: "Transaction Submitted",
            type: NOTIF_SUCCESS,
            description: purchase.dmaAgiSuccessful()
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (error.code === 103010) {
        dispatch(setDmaPrice(error.data))
        dispatch(getDMA())
        error = 'client_token_not_equal'
      }
      if (error.code === 103002) {
        error = 'InsufficientBalance'
      }

      dispatch(
        setNotification({
          title: "Purchase Failed",
          type: NOTIF_FAIL,
          description: purchase[error] || purchase["failed"]
        })
      );
      console.error(error);
      dispatch(setPaymentProcess(false));
    }

    dispatch(setPaymentProcess(false));
  }

  async function handleBuy() {
    if (paymentProcessing) return;

    function transactionCallBack() {
      setClose();
      dispatch(
        setNotification({
          title: "Purchase Processing",
          type: NOTIF_PROGRESSING,
          description:
            "Your purchase is pending and will be reviewed shortly. You will be notified when the on-chain transaction is submitted. (5s close)"
        })
      );
    }

    try {
      dispatch(setPaymentProcess(true));
      const signResponse = await dispatch(
        buyDMAByAGI({
          currency: 'USDT',
          amount: quantity,
          used_code: referralCode,
          client_token_to_pay: String(dmaPrice.usdt_price_list[quantity -1])
        })
      ).then((response) => {
        if (response?.error) return false;
        else return response;
      });

      if (signResponse === false) return;

      const transaction = true
      transactionCallBack();

      if (transaction) {
        dispatch(buyDMASuccess(quantity));
        dispatch(
          setNotification({
            title: "Transaction Submitted",
            type: NOTIF_SUCCESS,
            description: purchase.dmaAgiSuccessful()
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (error.code === 103010) {
        dispatch(setDmaPrice(error.data))
        dispatch(getDMA())
        error = 'client_token_not_equal'
      }
      if (error.code === 103002) {
        error = 'InsufficientBalance'
      }

      dispatch(
        setNotification({
          title: "Purchase Failed",
          type: NOTIF_FAIL,
          description: purchase[error] || purchase["failed"]
        })
      );
      console.error(error);
      dispatch(setPaymentProcess(false));
    }

    dispatch(setPaymentProcess(false));
  }

  const Logo = isBuyUsdt ? USDTBlueLogo : AGIBlueLogo

  return (
    <CustomDialog
      CloseButton={({ ...props }) => (
        <CloseButton type={downMd ? "filled" : "outlined"} {...props} />
      )}
      PaperProps={{
        "data-augmented-ui": downMd ? "tr-clip-x border" : "bl-clip-y br-clip border",
        className: classes.paper
      }}
      setClose={setClose}
      open={isOpen}>
      <Typography component="h1" sx={{ py: 1.5, fontSize: "28px", fontWeight: "600" }}>
        Check Out
      </Typography>

      <Box sx={{ overflowY: "scroll", overflowX: "visible", flexGrow: 1 }}>
        <Box pb={0.75} display="flex" flexWrap="wrap" alignItems="center">
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ display: "flex", alignItems: "center", width: "100%", mb: 1.125 }}>
            Enter Your Referral Code
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <TextField
              sx={{ width: "100%", flexGrow: 1 }}
              placeholder="Optional"
              variant="outlined"
              size="small"
              autoComplete="off"
              color="secondary"
              disabled={isReferralInputDisabled.some((fn) => fn())}
              value={
                isReferralInputDisabled.some((fn) => fn()) && referralCode === "000000"
                  ? "PUBLIC_SALE"
                  : referralCode
              }
              onBlur={(e) => handleValidateCode(e.target.value)}
              onChange={(e) => {
                const { value } = e.target;
                if (value.length > 6)
                  dispatch(setReferralCode(value.substring(0, 6).toUpperCase()));
                else dispatch(setReferralCode(value.toUpperCase()));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{ display: isValidCode === null && !validatingReferralCode && "none" }}
                    position="end">
                    {validatingReferralCode ? (
                      <LoadingSpinner />
                    ) : isValidCode || submittedReferralCode ? (
                      <Checkmark style={{ width: "14px", height: "14px" }} />
                    ) : (
                      referralCode.length > 0 && (
                        <Typography variant="caption" sx={{ fontSize: "0.6rem" }} color="#FFA3A3CC">
                          Invalid Code
                        </Typography>
                      )
                    )}
                  </InputAdornment>
                ),
                "data-augmented-ui": "tl-clip br-clip border"
              }}
            />
          </Box>
          <Typography variant="caption" color="textSecondary" sx={{ width: "100%", mt: 1 }}>
            Referral code cannot be changed after purchase
          </Typography>
        </Box>

        <Box
          pb={0.75}
          display={referralCode === BYBIT_CODE ? "flex" : "none"}
          flexWrap="wrap"
          alignItems="center">
          <TextField
            variant="outlined"
            size="small"
            name="bybitUID"
            type="text"
            placeholder="Enter your Bybit UID"
            color="secondary"
            value={bybitId}
            onChange={(e) => setBybitId(e.target.value)}
            sx={{ width: "100%", flexGrow: 1 }}
            InputProps={{
              "data-augmented-ui": "tl-clip br-clip border",
              autoComplete: "off",
              type: "text"
            }}
          />
        </Box>
        <Typography
          component="p"
          lineHeight={1.5}
          mt={1}
          mb={0.5}
          variant="caption"
          color="#FF9C07"
          fontSize={12}>
          Unit price will increase 25 USDT for every 100 DMAs sold after 10,000
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "flex-start",
            py: 1,
            justifyContent: "space-between"
          }}>
          <Box width="80%" display="flex" flexWrap="wrap" alignItems="center">
            <Typography color="textSecondary" variant="body2" sx={{ width: "100%" }}>
              Price Starting From
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              sx={{
                marginBottom: "5px",
                width: "100%",
                display: "flex",
                alignItems: "center"
              }}>
              <USDTLogo style={{ marginRight: "0.5rem" }} width={17} height={17} />{" "}
              <Typography
                component={motion.span}
                animate={{ opacity: isRefreshingPrice ? 0 : 1 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                variant="body1"
                color="inherit">
                {dmaPrice.usdt_price}
              </Typography>
              &nbsp;{DMA_CURRENCY}
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
              sx={{ textTransform: "uppercase", width: "100%" }}></Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start"
            }}>
            <Typography
              sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
              color="textSecondary"
              variant="body2">
              Quantity{" "}
              {quantity >= MAX_PURCHASE_ALLOWED && (
                <Typography variant="body2" color="#FFA3A3CC">
                  100 max
                </Typography>
              )}
            </Typography>
            <NumberTextField
              maxValue={
                MAX_PURCHASE_ALLOWED > availableSupply ? availableSupply : MAX_PURCHASE_ALLOWED
              }
              value={quantity}
              setValue={setQuantity}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            py: 1,
            mt: { md: 1.5 },
            mb: { xs: 0.75, md: 1.5 },
            borderTop: "1px solid #ffffff20",
            borderBottom: "1px solid #ffffff20"
          }}>
          <Typography variant="body2" sx={{ width: "100%" }}>
            Estimated Total Amount
          </Typography>
          <Typography
            color="primary"
            variant="h5"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center"
            }}>
            <Logo style={{ marginRight: "0.5rem" }} width={20} height={20} />{" "}
            <Typography
              component={motion.span}
              animate={{ opacity: isRefreshingPrice ? 0 : 1 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              variant="h5"
              color="inherit"
              sx={{
                fontSize: "24px !important",
              }}
              >
              {isBuyUsdt ? withComma(dmaPrice.usdt_price_list[quantity-1]) : withComma(dmaPrice.agi_price_list[quantity-1])}
            </Typography>
            &nbsp;<Box sx={{
               display: "inline-flex",
               alignItems: "center",
               fontSize: "18px",
               fontWeight: 600,
               gap: "2px",
               cursor: "pointer",
              }}
              onClick={handleToggle}
              >
                { isBuyUsdt ? 'USDT' : 'AGI' }
                <DownArrow ref={anchorRef} />
              </Box>
          </Typography>
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      sx={{
                        marginTop: "4px",
                        borderColor: "#333"
                      }}
                      autoFocusItem={openMenu}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      <MenuItem disableRipple selected={ !isBuyUsdt } onClick={(e) => {
                        setIsBuyUsdt(false)
                        handleClose(e)
                      }}>AGI</MenuItem>
                      <MenuItem disableRipple selected={isBuyUsdt} onClick={(e) => {
                        setIsBuyUsdt(true)
                        handleClose(e)
                        }}>USDT</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Box sx={{
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "end",
            width: "100%",
            gap: "16px"
          }}>
            <Box sx={{
              display: 'flex',
              fontSize: "12px",
              alignItems: "center",
              color: `rgba(255, 255, 255, 0.80)`,
              minWidth: 0,
            }}>
              Balance:&nbsp;<Typography
              component={motion.span}
              animate={{ opacity: isRefreshingPrice ? 0 : 1 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              color="inherit"
              sx={{
                fontSize: "12px",
                minWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              >
              {withComma(nativeBalance)}
            </Typography>&nbsp;{ isBuyUsdt ? 'USDT' : 'AGI' }
            <RefreshIcon
              style={{
                flexShrink: 0,
                marginLeft: "6px",
                cursor: "pointer"
              }}
              onClick={() => dispatch(getDMA())}
            />
            </Box>
            <Button
              disableRipple
              color="inherit"
              data-augmented-ui="tl-clip br-clip border"
              variant="outlined"
              onClick={() => window.open(PATH_EXTERNAL.dashboard.dma, "_blank")}
              sx={{
                marginTop: "2px",
                flexShrink: 0,
                minWidth: "90px",
                fontWeight: 400,
                height: "28px",
                fontSize: "12px",
                color: '#FAE232 !important',
                '--aug-border-bg': '#FAE232 !important',
              }}
            > Deposit
            </Button>
          </Box>
        </Box>

        <FormControlLabel
          sx={{ my: 1, mx: 0 }}
          value={checkedTerms}
          onChange={(e) => setCheckedTerms(e.target.checked)}
          control={<CustomCheckbox />}
          label={
            <Typography lineHeight="18px" variant="body2" color="textSecondary">
              I have read and agree to the Delysium{" "}
              <ExternalLink to={PATH_EXTERNAL.website.termsOfUse} title="Terms and Conditions" />{" "}
              and <ExternalLink to={PATH_EXTERNAL.website.privacyPolicy} title="Privacy Policy" />.
            </Typography>
          }
        />

        <FormControlLabel
          sx={{ my: 1, mx: 0, mb: { xs: 6, md: 1 } }}
          value={checkedConditions}
          onChange={(e) => setCheckedConditions(e.target.checked)}
          control={<CustomCheckbox />}
          label={
            <Typography lineHeight="18px" variant="body2" color="textSecondary">
              I have read and agree that DMAs are not investments.
            </Typography>
          }
        />
      </Box>
      <Box className={classes.buyButtonContainer}>
        <Button
          disableRipple
          data-augmented-ui="tl-clip br-clip"
          variant="contained"
          onClick={() => isBuyUsdt ? handleBuy() : handleAGIBuy()}
          disabled={isBuyButtonDisabled.some((fn) => fn())}
          startIcon={<ButtonLoadingIcon isLoading={paymentProcessing} />}>
          {paymentProcessing ? "processing..." : "buy now"}
        </Button>
      </Box>
    </CustomDialog>
  );
}

export default DMACheckoutDialog;
