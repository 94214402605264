/* eslint-disable no-useless-escape */
export function getURLParameters(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function removeEmptyParams(params) {
  var newParams = { ...params };
  for (const key of Object.keys(newParams)) {
    if (newParams[key] === "") {
      delete newParams[key];
    }
  }
  return newParams;
}

export function paramsToObject(paramsString = " ") {
  var search = paramsString.substring(1);
  if (search.length > 0)
    return JSON.parse(
      '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    );
  return {};
}

export function withComma(num = 0) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
