export const navbarHeight = "73px";

export const fullscreenStyle = (offsetTop = navbarHeight) => {
  return {
    minHeight: `calc(100vh - ${offsetTop})`
  };
};

export const fullscreenCenterStyle = (offsetTop = navbarHeight) => {
  return {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    ...fullscreenStyle(offsetTop)
  };
};
