import { IconButton } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseOutlinedIcon } from "../assets/icons/close-outlined.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";

// ----------------------------------------------------------------------

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["outlined", "filled"])
};
// ----------------------------------------------------------------------

const ICON_TYPES = {
  outlined: "outlined",
  filled: "filled"
};

function CloseButton({ type = "outlined", onClick, ...props }) {
  return (
    <IconButton
      disableRipple
      sx={{
        cursor: "pointer",
        position: "absolute",
        right: "1rem",
        top: type === ICON_TYPES.filled ? "2rem" : "1rem",
        width: "24px",
        height: "24px",
        opacity: "0.8",
        padding: 0,
        transition: (theme) =>
          theme.transitions.create("opacity", {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
          }),
        ...props?.sx
      }}
      {...props}
      onClick={onClick}>
      {type === ICON_TYPES.filled ? (
        <CloseIcon />
      ) : (
        <CloseOutlinedIcon style={{ width: "24px", height: "24px" }} />
      )}
    </IconButton>
  );
}

export default CloseButton;
