import CustomSkeleton from "../../components/Skeleton";
import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import InfoBox from "../../components/InfoBox";
import CustomDivider from "../../components/Divider";
import delysiumLoadingIcon from "../../assets/icons/delysium-loading-icon.svg";
import Copyright from "../../components/Copyright";
import { NFT_VIEW_SPACING } from "../../constants/styles";

const LoadingView = () => {
  const dividerSX = { my: 1 };
  return (
    <Grid container spacing={9} sx={{ px: 0, position: "relative" }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            position: "sticky",
            top: NFT_VIEW_SPACING.COVER_TOP_OFFSET,
            left: 0
          }}>
          <CustomDivider sx={{ mt: NFT_VIEW_SPACING.DIVIDER_MARGIN }} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ aspectRatio: "1/1" }}>
            <Box
              width="100%"
              maxWidth="104px"
              height="auto"
              component="img"
              src={delysiumLoadingIcon}
              alt="Loading Image"
            />
          </Box>
          <CustomDivider />

          <Copyright sx={{ display: { xs: "none", md: "initial" }, p: 0 }} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} mb={10}>
        <CustomDivider sx={{ mt: NFT_VIEW_SPACING.DIVIDER_MARGIN }} />
        <Box py={2} px={4}>
          <CustomSkeleton width="38.42%" />
          <CustomSkeleton width="25%" mt={4} />
        </Box>
        <CustomDivider sx={dividerSX} />
        <Box py={2} px={4}>
          <CustomSkeleton width="38.42%" />
          <CustomSkeleton width="25%" mt={4} />
        </Box>
        <CustomDivider sx={dividerSX} />
        <Grid container sx={{ mt: 1 }} pl={4} spacing={3}>
          {[...Array(8)].map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <InfoBox isSkeleton={true} />
            </Grid>
          ))}
        </Grid>
        <CustomDivider sx={dividerSX} />
        <Grid container sx={{ mt: 1 }} pl={4} spacing={3}>
          {[...Array(3)].map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <InfoBox isSkeleton={true} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LoadingView;
