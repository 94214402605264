import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../config";
import axios from "../../utils/axios";
import { NOTIF_FAIL, setNotification } from "./notifications";

const initialState = {
  isLoading: false,
  paymentProcessing: false,
  validatingReferralCode: false,
  isRefreshingPrice: false,
  submittedReferralCode: false,
  isValidCode: null,
  referralCode: "",
  balance: [],
  dmaPrice: {},
  DMA: null,
  hasError: false
};

export const slice = createSlice({
  name: "DMA",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setError(state, action) {
      state.isLoading = false;
      state.paymentProcessing = false;
      state.validatingReferralCode = false;
      state.hasError = action.payload;
    },

    setReferralCode(state, action) {
      state.referralCode = action.payload;
    },

    setDmaPrice(state, action) {
      state.dmaPrice = action.payload;
    },

    setLoading(state, action) {
      state.hasError = false;
      state.isLoading = action.payload;
    },

    setPaymentProcess(state, action) {
      state.paymentProcessing = action.payload;
    },

    setRefreshingPrice(state, action) {
      state.isRefreshingPrice = action.payload;
    },

    buyDMASuccess(state, action) {
      const { on_chain_info } = state.DMA;
      const { NumSold } = on_chain_info;
      state.paymentProcessing = false;
      state.DMA = {
        ...state.DMA,
        on_chain_info: { ...on_chain_info, NumSold: NumSold + action.payload }
      };
    },

    setBalance(state, action) {
      state.balance = action.payload
    },

    getDMASuccess(state, action) {
      state.isLoading = false;
      state.DMA = action.payload;
    },
    getDMAPriceSuccess(state, action) {
      state.isRefreshingPrice = false;
      state.DMA = action.payload;
    },

    setValidatingReferralCode(state, action) {
      state.validatingReferralCode = action.payload;
    },

    setsubmittedReferralCode(state, action) {
      state.submittedReferralCode = action.payload;
      state.isValidCode = true;
    },

    validateReferralCodeSuccess(state, action) {
      state.validatingReferralCode = false;
      state.isValidCode = action.payload;
    },

    submitReferralCodeSuccess(state, action) {
      state.submittedReferralCode = true;
    }
  }
});

export const { setPaymentProcess, setLoading, setDmaPrice, buyDMASuccess, hasError, setReferralCode } =
  slice.actions;

export function getDMAInitial() {
  return async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await axios.get("/product", { baseURL: config.DMA_API_URL });
      await dispatch(getRewardsBalance())
      await dispatch(getDMAPrice())
      dispatch(slice.actions.getDMASuccess(data.data));
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export function getDMA() {
  return async (dispatch) => {
    dispatch(slice.actions.setRefreshingPrice(true));
    try {
      const { data } = await axios.get("/product", { baseURL: config.DMA_API_URL });
      await dispatch(getRewardsBalance())
      await dispatch(getDMAPrice())
      dispatch(slice.actions.getDMAPriceSuccess(data.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDMAPrice(amount) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/reward/transactions/dma_price", { baseURL: config.DMA_API_URL });
      dispatch(slice.actions.setDmaPrice(data.data));
      return data.data
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export function signMessage(amount, used_code, bybit_user_id = "") {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/sign/with_code",
        { amount, used_code, bybit_user_id },
        { baseURL: config.DMA_API_URL }
      );
      if (data.code === 600 && data.error_detail === "record not found") {
        throw new Error("Your input referral code is invalid");
      }
      if (data.code === 600) {
        throw new Error("Your login credentials are not valid, please try logging in again");
      } else {
        return { ...data.data.sign_data, signature: data.data.signature };
      }
    } catch (error) {
      dispatch(
        setNotification({
          title: "Purchase Failed",
          type: NOTIF_FAIL,
          description:
            error?.message || "Failed connection to the remote server, please try again later."
        })
      );
      dispatch(slice.actions.setPaymentProcess(false));
      return { error: true };
    }
  };
}

export function getRewardsBalance() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/reward/balance`, {
        baseURL: config.DMA_API_URL
      });

      dispatch(slice.actions.setBalance(data.data.balance));
      return true;
    } catch (error) {}
  };
}

export function getReferralCodeInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.setValidatingReferralCode(true));
    try {
      const { data } = await axios.get("/invitation-code/", {
        baseURL: config.DMA_API_URL
      });

      if (data.code === 600 && data.error_detail.includes("token")) {
        throw new Error(`${data.error_detail}`);
      } else {
        if (data?.code !== 600) {
          dispatch(slice.actions.setReferralCode(data?.data?.referrer_code));
          dispatch(slice.actions.setsubmittedReferralCode(true));
          dispatch(slice.actions.setValidatingReferralCode(false));
        }
      }
    } catch (error) {
      dispatch(
        setNotification({
          title: "Error",
          type: NOTIF_FAIL,
          description: error.message || "Failed to obtain your referral code"
        })
      );

      dispatch(slice.actions.setError(true));
    }
    dispatch(slice.actions.setValidatingReferralCode(false));
  };
}

export function submitReferralCode(used_code) {
  if (used_code.replace(" ", "").length === 0) return;
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/invitation-code",
        { used_code },
        { baseURL: config.DMA_API_URL }
      );
      dispatch(slice.actions.submitReferralCodeSuccess(data.data.is_valid));
    } catch (error) {
      dispatch(
        setNotification({
          title: "Submission Failed",
          type: NOTIF_FAIL,
          description: error || "Failed to submit your referral code, please try again later."
        })
      );
      dispatch(slice.actions.setError(true));
    }
  };
}

export function buyDMAByAGI({
  amount,
  currency = 'DES',
  used_code,
  client_token_to_pay
}) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/reward/transactions/buy_dma`, {
        amount,
        currency,
        used_code,
        client_token_to_pay
      }, {
        baseURL: config.DMA_API_URL,
      });
      await dispatch(getRewardsBalance())
    } catch (error) {
      throw error
    }
  };
}

export function validateReferralCode(used_code) {
  if (used_code.replace(" ", "").length === 0) return;
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setValidatingReferralCode(true));
      const { data } = await axios.get(`/invitation-code/${used_code}`, {
        baseURL: config.DMA_API_URL
      });
      dispatch(slice.actions.validateReferralCodeSuccess(data.data.is_valid));
    } catch (error) {
      dispatch(slice.actions.setError(true));
    }
  };
}

export async function getDMASaleStatus() {
  try {
    const { data } = await axios.get(config.DMA_ARK_CONFIG_URL);
    return data?.marketplace?.saleDisabled;
  } catch (e) {
    return undefined;
  }
}
export default slice.reducer;
