import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchMode } from "../redux/slices/settings";

// ----------------------------------------------------------------------

function useSettings() {
  const dispatch = useDispatch();
  const { themeMode } = useSelector((state) => state.settings);
  const isLight = themeMode === "light";

  const handleToggleTheme = useCallback(
    () => dispatch(switchMode(isLight ? "dark" : "light")),
    [dispatch, isLight]
  );

  const handleChangeTheme = useCallback((value) => dispatch(switchMode(value)), [dispatch]);

  return {
    // Mode
    themeMode: themeMode,
    toggleMode: handleToggleTheme,
    selectMode: handleChangeTheme
  };
}

export default useSettings;
