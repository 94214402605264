import React, { createRef, useEffect, useState } from "react";
import { Typography, Box, IconButton, alpha, Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TabGroup from "../../components/TabGroup";
import CustomSkeleton from "../../components/Skeleton";
import { filterNFTs } from "../../redux/slices/NFT";
import { NFTCategories } from "../../utils/NFTs";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { ReactComponent as DropdownArrow } from "../../assets/arrow-dropdown.svg";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      "--aug-tl": "10px",
      "--aug-border-all": "1px",
      "--aug-border-top": "1.5px",
      "--aug-border-bg": theme.palette.divider
    },
    dropdown: {
      width: "100%",
      position: "relative",
      background: alpha(theme.palette.background.default, 0.85),
      "--aug-tl": "10px",
      "--aug-border-all": "1px",
      "--aug-border-top": "1.5px",
      "--aug-border-bg": theme.palette.divider,
      "--aug-bl": "6px",
      "--aug-bl-inset1": "calc(var(--aug-bl1) * 14)",
      "--aug-b-extend1": "50%"
    }
  };
});

// ----------------------------------------------------------------------

const SkeletonTabGroup = () => {
  const rootStyle = {
    padding: "36px 22px 60px"
  };

  return (
    <Box sx={rootStyle}>
      {[...Array(5)].map((el, index) => (
        <CustomSkeleton key={index} mb={5} />
      ))}
    </Box>
  );
};

// ----------------------------------------------------------------------

MobileCategoryMenu.propTypes = {
  isInventory: PropTypes.bool,
  isSkeleton: PropTypes.bool
};

// ----------------------------------------------------------------------
export default function MobileCategoryMenu({ isSkeleton = false, isInventory, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = createRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { NFTs, filters, pagination } = useSelector((state) => state.NFT);
  const [firstLoading, setFirstLoading] = useState(true);
  const isWrongNetwork = useIsWrongNetwork();

  const handleChange = (event, newValue) => {
    dispatch(filterNFTs({ category: newValue }));
  };

  useEffect(() => {
    setTimeout(() => {
      setFirstLoading(false);
    }, [1000]);
  }, []);

  return (
    <Box
      position="relative"
      display="flex"
      flexFlow="column nowrap"
      justifyContent="space-between"
      sx={{
        width: "100%",
        zIndex: { xs: 1000000, md: (theme) => theme.zIndex.appBar - 1 },
        ...props?.sx
      }}>
      <Box
        ref={ref}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        className={classes.paper}
        data-augmented-ui="tl-clip border">
        <Box
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
          sx={{
            height: 32,
            borderBottom: "1px solid",
            borderColor: "divider",
            cursor: "pointer"
          }}
          onClick={(e) => {
            setMenuOpen((prevState) => !prevState);
          }}>
          <Typography ml={7} component="span" fontWeight={600} variant="body2">
            {filters.category || "All Items"}
          </Typography>
          <Box display="flex" fontSize={14} mx={2}>
            <Typography component="span" color="primary" variant="body2" fontWeight="400">
              {isSkeleton ? "Loading... " : pagination.total_num_owned || pagination.total_num}
            </Typography>
            <Typography
              ml={0.35}
              component="span"
              variant="body2"
              fontWeight="400"
              textTransform="capitalize">
              {!isSkeleton && NFTs?.length === 1 ? "Item" : "Items"}
            </Typography>

            <IconButton
              disableRipple
              sx={{
                ml: 3,
                transform: menuOpen ? "rotate(180deg)" : "initial",
                transformOrigin: "center",
                transition: "all 0.1s ease"
              }}>
              <DropdownArrow />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {isSkeleton && firstLoading ? (
        <SkeletonTabGroup />
      ) : (
        menuOpen && (
          //@TODO: Get rid of constant numbers, calculate based on screen size and network status
          <Backdrop
            open={menuOpen}
            sx={{
              top: { xs: isWrongNetwork ? 175 : 95, sm: isWrongNetwork ? 155 : 95 },
              left: 0,
              px: 3,
              display: "flex",
              alignItems: "flex-start"
            }}>
            <TabGroup
              items={NFTCategories}
              selectedItem={filters.category}
              className={classes.dropdown}
              data-augmented-ui="bl-clip-y border"
              onChange={(event, newVal) => {
                setMenuOpen(false);
                handleChange(event, newVal);
              }}
              label="NFT Categories Menu"
            />
          </Backdrop>
        )
      )}
    </Box>
  );
}
