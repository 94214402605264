import { Box } from "@mui/system";
import React from "react";
import { ReactComponent as Divider } from "../assets/divider.svg";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------

CustomDivider.propTypes = {
  sx: PropTypes.object
};
// ----------------------------------------------------------------------

function CustomDivider({ sx = {}, ...props }) {
  return (
    <Box {...props} sx={{ display: "flex", width: "100%", ...sx }}>
      <Divider style={{ width: { sm: "100%", md: "622px" }, height: "auto" }} />
    </Box>
  );
}

export default CustomDivider;
