import jwtDecode from "jwt-decode";
import { createSlice } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";
import { SSO_TOKEN_NAME } from "../../constants/cookies";
import axios from "../../utils/axios";
import { config } from "../../config";
import { NOTIF_FAIL, setNotification } from "./notifications";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  user: null,
  token: null,
  isAuthenticated: false
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setUser(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },

    invalidateUser(state) {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    }
  }
});

// Reducer
export default slice.reducer;
export const { hasError, setUser } = slice.actions;

// ----------------------------------------------------------------------
function removeJWTCookie() {
  const cookies = new Cookies();
  cookies.remove(SSO_TOKEN_NAME);
}

export const initializeUser = (token) => {
  return async (dispatch) => {
    try {
      if (!isValidToken(token)) {
        removeJWTCookie();
        dispatch(slice.actions.invalidateUser());
      }
      dispatch(slice.actions.setUser({ user: jwtDecode(token), token }));
    } catch (e) {
      console.error(e);
    }
  };
};

export const authUser = (token) => {
  return async (dispatch) => {
    try {
      await axios.get("/auth/me", {
        baseURL: config.SSO_API_URL,
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      if (error?.code === 400502) {
        dispatch(slice.actions.invalidateUser());
        removeJWTCookie();
      }
      console.error(error);
    }
  };
};

export const logout = (token) => {
  return async (dispatch) => {
    try {
      await axios.post(
        "/auth/logout",
        {},
        {
          baseURL: config.SSO_API_URL,
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      dispatch(slice.actions.invalidateUser());
      removeJWTCookie();
    } catch (error) {
      if (error.code === 400502) {
        dispatch(
          setNotification({
            title: "Error",
            type: NOTIF_FAIL,
            description: "You are already logged out"
          })
        );
        dispatch(slice.actions.invalidateUser());
        removeJWTCookie();
      }
    }
  };
};

export const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (e) {
    console.error(e);
  }
};
