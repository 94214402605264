export const TIME_ZONE = "asia/shanghai";

export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export const decomposeTimeLeft = (timeLeft) => {
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  return [days, hours, minutes, seconds];
};
