// import { Icon } from '@iconify/react';
// import infoFill from '@iconify-icons/eva/info-fill';
// import alertCircleFill from '@iconify-icons/eva/alert-circle-fill';
// import alertTriangleFill from '@iconify-icons/eva/alert-triangle-fill';
// import checkmarkCircle2Fill from '@iconify-icons/eva/checkmark-circle-2-fill';

// ----------------------------------------------------------------------

export default function Alert({ theme }) {
  const isLight = theme.palette.mode === "light";

  const standardStyle = (color) => {
    return {
      color: theme.palette[color][isLight ? "darker" : "lighter"],
      backgroundColor: theme.palette[color][isLight ? "lighter" : "darker"]
    };
  };

  const filledStyle = (color) => {
    return {
      color: theme.palette[color].contrastText
    };
  };

  const outlinedStyle = (color) => {
    return {
      color: theme.palette[color][isLight ? "darker" : "lighter"],
      border: `solid 1px ${theme.palette[color][isLight ? "light" : "dark"]}`,
      backgroundColor: theme.palette[color][isLight ? "lighter" : "darker"]
    };
  };

  return {
    MuiAlert: {
      styleOverrides: {
        message: {
          "& .MuiAlertTitle-root": {
            marginBottom: 4
          }
        },
        action: {
          "& button:not(:first-of-type)": {
            marginLeft: 8
          }
        },

        standardInfo: standardStyle("info"),
        standardSuccess: standardStyle("success"),
        standardWarning: standardStyle("warning"),
        standardError: standardStyle("error"),

        filledInfo: filledStyle("info"),
        filledSuccess: filledStyle("success"),
        filledWarning: filledStyle("warning"),
        filledError: filledStyle("error"),

        outlinedInfo: outlinedStyle("info"),
        outlinedSuccess: outlinedStyle("success"),
        outlinedWarning: outlinedStyle("warning"),
        outlinedError: outlinedStyle("error")
      }
    }
  };
}
