import { Box, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Common/Navbar";
import CategoryMenu from "./CategoryMenu";
import Filters from "./Filters";
import PropTypes from "prop-types";
import useBreakpoints from "../../hooks/useBreakpoints";
import MobileNavbar from "../Common/MobileNavbar";
import MobileCategoryMenu from "./MobileCategoryMenu";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "../../components/Animate";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";
import GradientBackground from "../Common/GradientBackground";
import { useLocation } from "react-router";
import { PATH_INVENTORY } from "../../routes/paths";
import { setWalletConnectionOpen } from "../../redux/slices/wallet";
import { useWeb3React } from "@web3-react/core";
// import Banner from "../../components/Banner";

// ----------------------------------------------------------------------

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};
// ----------------------------------------------------------------------

const SCROLL_DIR = { up: "up", down: "down", none: null };

function MainLayout({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { active } = useWeb3React();
  const { isLoading } = useSelector((state) => state.NFT);
  const isWrongNetwork = useIsWrongNetwork();
  const downMd = useBreakpoints("down", "md");
  const [scrollDir, setScrollDir] = useState(SCROLL_DIR.none);
  const searchFilterIsShown =
    downMd && (scrollDir === SCROLL_DIR.up || scrollDir === SCROLL_DIR.none);

  useEffect(() => {
    if (location.pathname.includes(PATH_INVENTORY.root)) {
      if (!active) dispatch(setWalletConnectionOpen(true));
      else dispatch(setWalletConnectionOpen(false));
    }
  }, [location.pathname, active, dispatch]);

  useEffect(() => {
    if (downMd) {
      const threshold = 80;
      let lastScrollY = window.pageYOffset;
      let ticking = false;

      const updateScrollDir = () => {
        const scrollY = window.pageYOffset;
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        if (scrollY > lastScrollY) setScrollDir(SCROLL_DIR.down);
        else if (scrollY < lastScrollY) setScrollDir(SCROLL_DIR.up);
        else setScrollDir(SCROLL_DIR.none);
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
      };

      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(updateScrollDir);
          ticking = true;
        }
      };

      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [scrollDir, downMd]);

  //@TODO: cleanup rendering of mobile/desktop screens
  return (
    <>
      <GradientBackground
        sx={{
          height: { xs: isWrongNetwork ? `120px` : "60px", md: isWrongNetwork ? `172px` : "100px" }
        }}
      />

      {downMd ? <MobileNavbar /> : <Navbar />}
      {/* {!downMd && <Banner />} */}
      <Container maxWidth={false} sx={{ py: 2, overflow: "initial", position: "relative" }}>
        <Box sx={{ display: "flex", flexWrap: { xs: "wrap", md: "nowrap" } }}>
          <AnimatePresence initial={false}>
            {searchFilterIsShown && (
              <Box
                component={motion.div}
                {...varFadeInDown}
                initial={{ opacity: 0 }}
                animate={searchFilterIsShown ? varFadeInDown.animate : varFadeInDown.exit}
                sx={{
                  position: isWrongNetwork ? "sticky" : "fixed",
                  left: 0,
                  top: {
                    xs: isWrongNetwork ? "120px" : "40px",
                    sm: isWrongNetwork ? "100px" : "45px"
                  },
                  p: isWrongNetwork && downMd ? 1 : 3,
                  pt: 3,
                  pb: 0.5,
                  minWidth: "100%",
                  zIndex: (theme) => theme.zIndex.appBar - 1,
                  backgroundColor: (theme) => theme.palette.background.default
                }}>
                <MobileCategoryMenu />
                <Filters />
              </Box>
            )}
          </AnimatePresence>

          <Box sx={{ display: { xs: "none", md: "initial" }, minWidth: 242 }}>
            <CategoryMenu isSkeleton={isLoading} />
          </Box>

          <Box display="flex" flexDirection="column" flexWrap="nowrap" flexGrow={1}>
            {/* {downMd && <Banner />} */}
            {!downMd && <Filters />}
            {children}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default MainLayout;
