import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { getConnectorName, injected, walletconnect, walletlink } from "../utils/connectors";
import { getLocalStorage } from "../utils/localStorage";

import { COINBASE, METAMASK, WALLETCONNECT } from "../utils/wallet";

export function useEagerConnect() {
  const { activate, active, connector } = useWeb3React();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    const connectorName = getLocalStorage("connector");
    if (connectorName !== getConnectorName())
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          if (connectorName === WALLETCONNECT)
            activate(walletconnect, undefined, true).catch(() => setTried(true));
          else if (connectorName === COINBASE)
            activate(walletlink, undefined, true).catch(() => setTried(true));
          else if (connectorName === METAMASK)
            activate(injected, undefined, true).catch(() => setTried(true));
        } else {
          setTried(true);
        }
      });
  }, [connector, activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}
