/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Alert, Box } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeIn } from "./Animate";
import useIsWrongNetwork from "../hooks/useIsWrongNetwork";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import { getCorrectChain } from "../utils/chains";
// ----------------------------------------------------------------------

ChangeNetworkAlert.propTypes = {
  height: PropTypes.number
};

// ----------------------------------------------------------------------
const containerSX = {
  zIndex: -2,
  mt: 2,
  mb: 1
};

const alertSX = {
  fontSize: { xs: 12, md: 14 },
  fontWeight: "bold",
  textAlign: "center",
  justifyContent: "center",
  background: (theme) => theme.palette.text.primary,
  color: (theme) => theme.palette.background.default,
  px: 0.75,
  py: 0
};

function ChangeNetworkAlert() {
  const isWrongNetwork = useIsWrongNetwork();
  const { pathname } = useLocation();
  const { name: chainName } = getCorrectChain(pathname);

  return (
    <AnimatePresence presenceAffectsLayout>
      {isWrongNetwork && (
        <Box component={motion.div} sx={containerSX} {...varFadeIn}>
          <Alert icon={false} sx={alertSX}>
            You're viewing data from the {chainName}, but your wallet is connected to another
            network. Please change your network to {chainName}.
          </Alert>
        </Box>
      )}
    </AnimatePresence>
  );
}

export default ChangeNetworkAlert;
