import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getNFT, getOwnedNFT } from "../../redux/slices/NFT";
import { CURRENCY, getRarityColor, getUSDCPrice } from "../../utils/NFTs";
import { ReactComponent as USDCBlueLogo } from "../../assets/icons/usdc_blue.svg";
import { Box } from "@mui/system";
import InfoBox from "../../components/InfoBox";
import CustomDivider from "../../components/Divider";
import CheckoutDialog from "../../components/NFTCheckoutDialog";
import { SaleStageView } from "./SaleStageView";
import { SALE_STAGE, SALE_MODE, useSaleCases } from "../../hooks/useSaleCases";
import { useWeb3React } from "@web3-react/core";
import { setWalletConnectionOpen } from "../../redux/slices/wallet";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";
import { navbarHeight } from "../../utils/styles";
import { ReactComponent as Owned } from "../../assets/icons/owned_icon.svg";
import LoadingView from "../ProductLoadingView";
import { NFT_VIEW_SPACING as SPACING } from "../../constants/styles";
import CopyrightView from "../../components/Copyright";
import { Helmet } from "react-helmet";

const SHARED_PADDING_STYLE = {
  px: { md: SPACING.CONTENT_PADDING },
  pt: { md: SPACING.CONTENT_PADDING }
};

const InfoItems = ({ infoObject, infoTitle }) => {
  return infoObject ? (
    <React.Fragment>
      <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />
      <Box sx={{ mt: { xs: 2 }, ...SHARED_PADDING_STYLE }}>
        <Typography
          sx={{ mb: { md: 2.5 }, textTransform: "capitalize" }}
          component="h3"
          color="primary">
          {infoTitle}
        </Typography>

        <Grid container columnSpacing={3} rowSpacing={1} mt={1.25} width="100%">
          {Object.keys(infoObject).map((subItemKey, index) => {
            return (
              <Grid key={index} item xs={6} md={4}>
                <InfoBox key={subItemKey} title={subItemKey} subtitle={infoObject[subItemKey]} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  ) : null;
};

const RarityTag = ({ isOwned, NFT }) => {
  return (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end"
        }}
        variant="body2"
        color="text.secondary"
        component="div">
        {/* @TODO: More Dynamic condition than hard coding "Collectible" */}
        {isOwned && NFT?.category === "Collectible" && (
          <Typography
            display="flex"
            alignItems="center"
            variant="body2"
            textTransform="uppercase"
            sx={{
              color: (theme) => alpha(theme.palette.text.secondary, 0.7)
            }}>
            <Owned style={{ marginRight: "0.5rem" }} />
            <Typography mr={0.5} color="primary">
              {NFT?.amount}
            </Typography>
            Owned
          </Typography>
        )}
      </Typography>

      <Typography
        fontWeight={900}
        letterSpacing={2}
        fontSize={16}
        mt={0.5}
        component="h5"
        textTransform="uppercase"
        sx={{ color: getRarityColor(NFT?.rarity) }}>
        ({NFT?.rarity})
      </Typography>
    </>
  );
};

const CountdownView = ({ timeLeft, isWaiting, isPreSale, isOpenSeaSale }) => {
  const [days, hours, minutes, seconds] = timeLeft;

  if (isOpenSeaSale) {
    return null;
  }

  function Placeholder({ children }) {
    return (
      <Typography variant="caption" color="primary">
        {" "}
        {children}{" "}
      </Typography>
    );
  }

  return (
    <Box display="flex" mt={1}>
      <Typography variant="caption" color="textSecondary">
        {/* Render units in singular or plural form */}
        {isPreSale ? "Pre-Sale" : "Official Sale"}
        &nbsp;{isWaiting ? "Starts" : "Ends"} in
        <Placeholder>{days}</Placeholder>
        Days,
        <Placeholder>{hours}</Placeholder>
        Hours,
        <Placeholder>{minutes}</Placeholder>
        Mins,
        <Placeholder>{seconds}</Placeholder>
        Secs
      </Typography>
    </Box>
  );
};

const buyButtonSX = {
  display: "flex",
  flexFlow: "column nowrap",
  textTransform: "uppercase",
  width: { xs: "100%", md: "70%" },
  maxWidth: { xs: "initial", md: 356 },
  my: { xs: 1, md: 0 },
  fontSize: 16
};

const imageContainerSX = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  zIndex: -1,
  maxWidth: { sm: "662px", md: "min(47%, 616px)" },
  gridColumnStart: 0,
  gridColumnEnd: 1,
  width: "initial",
  pr: { md: 4 },
  position: { sm: "initial", md: "fixed" },

  right: {
    xs: "initial",
    md: "calc(50%)",
    lg: "min(50.35%, calc(100vw - 50%))"
  }
};

const imageSX = {
  height: "auto",
  width: "100%",
  maxHeight: "70vh",
  backgroundPosition: "center",
  backgroundSize: "104px auto"
};

const buttonContainerSX = {
  display: "flex",
  justifyContent: "space-between",
  width: { xs: "100%", md: "90%" },
  flexFlow: { xs: "column nowrap", sm: "row nowrap" },
  mt: { xs: 1, md: 2.5 }
};

const NFTView = ({ isOwned }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const { modelId } = useParams();
  const { active, account } = useWeb3React();
  const { NFT, isLoading } = useSelector((state) => state.NFT);
  const isWrongNetwork = useIsWrongNetwork();
  const [isBuyOpen, setIsBuyOpen] = useState(false);
  const hasVideo = NFT?.metadata.animation_url ? true : false;
  const {
    // isOnWhitelist,
    timeLeft,
    currentSaleStage,
    currentSaleMode,
    preSaleDateRange,
    publicSaleDateRange
  } = useSaleCases({
    preSaleStartDate: NFT?.on_chain_info.TimePreorderStart * 1000,
    preSaleEndDate: NFT?.on_chain_info.TimePreorderEnd * 1000,
    publicSaleStartDate: NFT?.on_chain_info.TimeSaleStart * 1000,
    publicSaleEndDate: NFT?.on_chain_info.TimeSaleEnd * 1000
  });

  // Computed
  const stockQuantity = NFT?.on_chain_info.MaxSupply - NFT?.on_chain_info.NumSold;
  const buyButtonText = "Buy Now";

  // Stage alias
  const isWaitingPreSale = currentSaleStage === SALE_STAGE.WAITING_PRE_SALE;
  const isPreSale = currentSaleStage === SALE_STAGE.PRE_SALE;
  const isWaitingPublicSale = currentSaleStage === SALE_STAGE.WAITING_PUBLIC_SALE;
  const isPublicSale = currentSaleStage === SALE_STAGE.PUBLIC_SALE;
  const isOpenSeaSale = currentSaleStage === SALE_STAGE.OPENSEA_SALE; // Currently we will not have opensea sale

  // Mode alias
  const isPreSaleOnlyMode = currentSaleMode === SALE_MODE.PRE_SALE_ONLY;
  const isPublicSaleOnlyMode = currentSaleMode === SALE_MODE.PUBLIC_SALE_ONLY;
  const isSaleDisabledMode = currentSaleMode === SALE_MODE.DISABLED || isOwned;
  const isDisabledBuyButtonConds = [
    () => isPreSale,
    // () => isPreSale && !isOnWhitelist,
    () => isOpenSeaSale,
    () => isWaitingPreSale || isWaitingPublicSale,
    () => !stockQuantity,
    () => isLoading,
    () => isWrongNetwork
  ];

  function handleClickOnBuy() {
    if (stockQuantity === 0) return;
    if (active) setIsBuyOpen(true);
    else dispatch(setWalletConnectionOpen(true));
  }

  useEffect(() => {
    const { current: videoElement } = videoRef;
    videoElement.setAttribute("muted", "");
  }, []);

  useEffect(() => {
    if (isOwned && account)
      dispatch(getOwnedNFT({ walletAddress: account, filters: { model_id: modelId } }));
    else if (!isOwned) dispatch(getNFT({ modelId }));
  }, [dispatch, isOwned, account, modelId]);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Grid
      container
      sx={{
        display: "grid",
        px: 0,
        position: "relative",
        gridTemplateColumns: {
          xs: "100%",
          md: "50% 50%"
        }
      }}>
      {NFT?.metadata.name && (
        <Helmet>
          <title>
            {`${NFT?.metadata.name}${isOwned ? " - Inventory " : " "} - Delysium Marketplace`}
          </title>
        </Helmet>
      )}

      <Grid item sx={{ position: "relative" }}>
        <Box sx={{ display: { md: "none" }, my: 1.875 }}>
          <Typography textTransform="uppercase" fontSize={24} fontWeight="bold" component="h1">
            {NFT?.metadata.name}
          </Typography>
          <RarityTag isOwned={isOwned} NFT={NFT} />
        </Box>

        <Box
          sx={{
            ...imageContainerSX,
            top: `calc(${SPACING.DIVIDER_MARGIN * 8}px + ${navbarHeight} + ${
              isWrongNetwork ? "60px" : "0px"
            })`
          }}>
          <CustomDivider />
          <Box
            ref={videoRef}
            loop
            autoPlay
            muted
            playsInline
            component={hasVideo ? "video" : "img"}
            src={NFT?.metadata.animation_url || NFT?.metadata.image}
            controls={false}
            alt={NFT?.metadata.name}
            sx={{ ...imageSX, minHeight: hasVideo ? "40vh" : "50vh" }}
          />
          <CustomDivider />

          <CopyrightView sx={{ display: { xs: "none", md: "initial" }, p: 0 }} />
        </Box>
      </Grid>
      <Grid item sx={{ pl: { md: 4 } }}>
        <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN, display: { xs: "none", md: "flex" } }} />
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexFlow: "column nowrap",
            ...SHARED_PADDING_STYLE
          }}>
          <Typography
            sx={{ textTransform: "uppercase", fontSize: 28, fontWeight: "bold" }}
            component="h1">
            {NFT?.metadata.name}
          </Typography>

          <RarityTag isOwned={isOwned} NFT={NFT} />
        </Box>

        {/* ------------- TRADE/BUY/SALE -------------   */}
        {!isSaleDisabledMode && (
          <>
            <CustomDivider
              sx={{ mt: SPACING.DIVIDER_MARGIN, display: { xs: "none", md: "flex" } }}
            />

            <Box sx={{ ...SHARED_PADDING_STYLE, mt: { xs: 1, md: 0 } }}>
              {!isOpenSeaSale && (
                <>
                  <Typography
                    sx={{ mb: { md: 0.625 } }}
                    component="h3"
                    fontSize={16}
                    color="primary">
                    Price
                  </Typography>

                  <Box sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
                    <USDCBlueLogo width={20} height={20} style={{ marginRight: "0.5rem" }} />
                    <Typography color="primary" variant="h4" component="p">
                      {getUSDCPrice(NFT?.on_chain_info?.Price)} {CURRENCY}
                    </Typography>
                  </Box>
                </>
              )}

              <Box sx={buttonContainerSX}>
                <Button
                  disableRipple
                  data-augmented-ui="tl-clip br-clip"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isDisabledBuyButtonConds.some((fn) => fn())}
                  onClick={handleClickOnBuy}
                  sx={buyButtonSX}>
                  {stockQuantity <= 0 ? "Sold Out" : isOpenSeaSale ? "Sale Ended" : buyButtonText}
                  <Typography
                    variant="body2"
                    sx={{ textTransform: "capitalize", fontSize: 12, fontWeight: 300 }}>
                    {!isOpenSeaSale && stockQuantity > 0 && `Available Supply ${stockQuantity}`}
                  </Typography>
                </Button>
              </Box>

              <CountdownView
                isWaiting={isWaitingPreSale || isWaitingPublicSale}
                timeLeft={timeLeft}
                isPreSale={isWaitingPreSale || isPreSale}
                isOpenSeaSale={isOpenSeaSale}
              />
            </Box>

            <SaleStageView
              isWaitingPreSale={isWaitingPreSale}
              isPreSale={isPreSale}
              isWaitingPublicSale={isWaitingPublicSale}
              isPublicSale={isPublicSale}
              isOpenSeaSale={isOpenSeaSale}
              isPreSaleOnlyMode={isPreSaleOnlyMode}
              isPublicSaleOnlyMode={isPublicSaleOnlyMode}
              preSaleDateRange={preSaleDateRange}
              publicSaleDateRange={publicSaleDateRange}
              sx={{ ...SHARED_PADDING_STYLE }}
            />
          </>
        )}

        {/* ------------- INTRO -------------   */}

        <CustomDivider sx={{ mt: SPACING.DIVIDER_MARGIN }} />

        <Box sx={{ ...SHARED_PADDING_STYLE, mt: { xs: 1, md: 0 } }}>
          <Typography sx={{ mb: 0.625, fontSize: 16 }} component="h3" color="primary">
            Intro
          </Typography>

          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ mt: 1.25, opacity: 0.7, fontSize: { xs: 12 }, fontWeight: 300 }}>
            {NFT?.metadata.description}
          </Typography>
        </Box>

        {/* ------------- INFO -------------   */}
        <InfoItems infoObject={NFT?.metadata?.info?.properties} infoTitle="properties" />
        <InfoItems infoObject={NFT?.metadata?.info?.weapon} infoTitle="weapon" />
        <InfoItems infoObject={NFT?.metadata?.info?.module} infoTitle="module" />

        <CopyrightView
          sx={{ display: { xs: "block", md: "none" }, textAlign: "center" }}
          textProps={{ sx: { display: "initial", margin: "0 auto" } }}
        />
      </Grid>
      {isBuyOpen && (
        <CheckoutDialog
          isOpen={isBuyOpen}
          setClose={() => setIsBuyOpen(false)}
          isPreSale={isPreSale}
          isPublicSale={isPublicSale}
        />
      )}
    </Grid>
  );
};

export default NFTView;
