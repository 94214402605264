import { Container } from "@mui/material";
import React from "react";
import Navbar from "../Common/Navbar";
import PropTypes from "prop-types";
import useBreakpoints from "../../hooks/useBreakpoints";
import MobileNavbar from "../Common/MobileNavbar";
import GradientBackground from "../Common/GradientBackground";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";

// ----------------------------------------------------------------------

NFTLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};
// ----------------------------------------------------------------------

function NFTLayout({ children }) {
  const downMd = useBreakpoints("down", "md");
  const isWrongNetwork = useIsWrongNetwork();
  return (
    <>
      {isWrongNetwork && <GradientBackground sx={{ height: { xs: "75px", md: "135px" } }} />}

      {downMd ? <MobileNavbar /> : <Navbar />}

      <Container maxWidth={false} sx={{ maxWidth: "1400px" }}>
        {children}
      </Container>
    </>
  );
}

export default NFTLayout;
