import { Divider, Typography, alpha } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomDialog from "../../../components/Dialog";
import CoinbaseIcon from "../../../assets/icons/wallet/coinbase.png";
import WalletConnectIcon from "../../../assets/icons/wallet/walletconnect.png";
import MetaMaskIcon from "../../../assets/icons/wallet/metamask.png";
import { ReactComponent as QuestionMark } from "../../../assets/icons/question_mark.svg";
import { COINBASE, METAMASK, WALLETCONNECT } from "../../../utils/wallet";
import { injected as metamask, walletconnect, walletlink } from "../../../utils/connectors";
import WalletLogin from "./WalletLogin";
import { useDispatch, useSelector } from "react-redux";
import { setWalletConnectionOpen } from "../../../redux/slices/wallet";
import ReactGA from "react-ga4";
// import { isMobile } from "../../../utils/env";

const WalletLoginDialog = ({ ...props }) => {
  const dispatch = useDispatch();
  const { isWalletConnectionOpen } = useSelector((state) => state.wallet);

  function closeDialog() {
    dispatch(setWalletConnectionOpen(false));
  }

  return (
    <CustomDialog
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            sm: "initial",
            md: "384px"
          }
        }
      }}
      setClose={closeDialog}
      open={isWalletConnectionOpen}
      {...props}>
      <Typography component="h2" variant="h5" fontWeight="bold">
        Connect Your Wallet
      </Typography>

      <WalletLogin
        closeDialog={closeDialog}
        title={METAMASK}
        Icon={MetaMaskIcon}
        connector={metamask}
      />

      <Divider />
      <WalletLogin
        closeDialog={closeDialog}
        title={COINBASE}
        Icon={CoinbaseIcon}
        connector={walletlink}
      />
      <Divider />

      <WalletLogin
        closeDialog={closeDialog}
        title={WALLETCONNECT}
        Icon={WalletConnectIcon}
        connector={walletconnect}
      />
      <Divider />

      <Box my={2} display="flex" flexWrap="nowrap" alignItems="center">
        <QuestionMark width={20} height={20} />
        <Typography
          color="textSecondary"
          variant="caption"
          sx={{ ml: 2, width: { xs: "100%", sm: "70%" } }}>
          Get help connecting your wallet Chat with us on{" "}
          <Typography
            onClick={() => {
              ReactGA.event(`enter_discord_page`);
            }}
            variant="caption"
            color="primary"
            component="a"
            href="https://discord.com/invite/delysium">
            Discord
          </Typography>
        </Typography>
      </Box>
    </CustomDialog>
  );
};

export default WalletLoginDialog;
