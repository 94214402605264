import { alpha } from "@mui/material";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// ***** For now we are only using PRIMARY and SECONDARY ****

const PRIMARY = {
  lighter: alpha("#7FFAE2", 0.6),
  light: alpha("#7FFAE2", 0.8),
  main: "#7FFAE2"
  // dark: "#007B55",
  // darker: "#005249",
};

const SECONDARY = {
  lighter: alpha("#FF508F", 0.6),
  light: alpha("#FF508F", 0.8),
  main: "#FF508F"
  // dark: "#1939B7",
  // darker: "#091A7A",
};

const INFO = {
  lighter: "#C8FACD",
  light: "#5BE584",
  main: "#00AB55",
  dark: "#007B55",
  darker: "#005249"
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D"
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01"
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E"
};

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#4E4E4E",
  800: "#212B36",
  900: "#000",
  500_8: alpha("#000", 0.08),
  500_12: alpha("#000", 0.12),
  500_16: alpha("#000", 0.16),
  500_24: alpha("#000", 0.24),
  500_32: alpha("#000", 0.32),
  500_48: alpha("#000", 0.48),
  500_50: alpha("#000", 0.5),
  500_80: alpha("#000", 0.8)
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  divider: "#323232",
  action: {
    hover: "#03ffcee6",
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  // LIGHT MODE
  light: {
    ...COMMON,
    mode: "light",
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500]
    },
    background: {
      paper: "#fff",
      default: "#fff",
      neutral: GREY[200]
    },
    action: {
      active: GREY[600],
      ...COMMON.action
    }
  },

  // DARK MODE
  dark: {
    ...COMMON,
    mode: "dark",
    text: {
      primary: GREY[0],
      secondary: alpha(GREY[0], 0.8),
      disabled: alpha(GREY[0], 0.7)
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16]
    },
    action: {
      active: GREY[500],
      ...COMMON.action
    }
  }
};

export default palette;
